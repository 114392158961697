import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import * as CONST from '../../../../constants/FieldsConst';
import { getAllTiposEventosAction } from '../TiposEventosAction';

const SelectFieldTiposEventosComponent = ({
  onChange,
  id = 'select-tiposEventos',
  data,
  value = '',
  isEmpty = false,
  isAll = true,
  selects = [],
  required = false,
  error = false,
}) => {
  const dispatch = Redux.useDispatch();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const tiposEventosStore = Redux.useSelector((state) => state.tiposEventos);

  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (!data && tiposEventosStore.all.length === 0) {
        await getAllTiposEventosAction(dispatch, entidadesStore.activo.id);
        setItems(tiposEventosStore.all);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    let items = [];
    if (selects.length > 0) {
      tiposEventosStore.all.forEach((se) => {
        const existSeletc = selects.some((item) => item.id === se.id);
        if (!existSeletc) items.push(se);
      });
      setItems(items);
    } else {
      if (data) {
        setItems(data);
      } else {
        setItems(tiposEventosStore.all);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selects]);

  return (
    <FormControl fullWidth size="small" required={required} error={error}>
      <InputLabel id={`label_${id}`}>Tipo de evento</InputLabel>
      <Select
        id={CONST.ID}
        value={value?.toString()}
        labelId={`label_${id}`}
        label="Tipo de evento"
        onChange={(e) => onChange(e.target.value)}
      >
        <MenuItem key={`${id}-000-item`} value={''}>
          Seleccionar
        </MenuItem>
        {isEmpty ||
          (isAll && (
            <MenuItem key={`${id}-000-item`} value={''}>
              {isAll ? ' TODOS' : 'NINGUNO'}
            </MenuItem>
          ))}
        {items.map((el, index) => {
          return (
            <MenuItem key={`item_${id}_${index}`} value={el.id}>
              {el.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SelectFieldTiposEventosComponent;
