import { Card, Grid, Paper } from '@mui/material';
import * as React from 'react';
import { Modal } from '../../components';
import ReservationUsedBlocks from '../../components/ReservationUsedBlocks';
import FormReservasEspeciales from './components/FormReservasEspeciales';

const PanelReservasEspecialesView = () => {
  const [isMobile, setIsMobile] = React.useState(false);

  const [refresh, setRefresh] = React.useState(false);
  const [areaIds, setAreaIds] = React.useState([]);
  const [dates, setDates] = React.useState([]);

  const [openModalForm, setOpenModalForm] = React.useState(false);

  return (
    <Grid container spacing={2} sx={{ marginTop: '1px' }}>
      <Grid item md={4}>
        <Card>
          <FormReservasEspeciales
            setIsMobile={() => setIsMobile(!isMobile)}
            setOpenModalForm={(val) => {}}
            onRefresh={() => {
              setRefresh(true);
              setTimeout(() => {
                setRefresh(false);
              }, 100);
            }}
            onChangeAreas={(val) => setAreaIds(val)}
            onChangeDates={(val) => setDates(val)}
          />
        </Card>
      </Grid>
      <Grid item md={8}>
        <Paper elevation={2} sx={{ p: 2 }}>
          <ReservationUsedBlocks
            areaIds={areaIds}
            dates={dates}
            refresh={refresh}
          />
        </Paper>
      </Grid>
      <Modal
        id="modalFormAreas"
        title="Nueva reserva"
        open={openModalForm}
        onClose={() => setOpenModalForm(!openModalForm)}
      ></Modal>
    </Grid>
  );
};

export default PanelReservasEspecialesView;
