import axios from 'axios';
import { baseApi, token } from '../../services/Apis';

export const apiKeysApi = `${baseApi}/api-keys`;

export const getAllApiKeysService = async (params) => {
  console.log('object');
  const res = await axios.get(apiKeysApi, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveApiKeysService = async (id, data) => {
  if (data.logo) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    data = formData;
  } else {
    delete data.logo;
  }

  let res;
  if (id) {
    res = await axios.put(`${apiKeysApi}/${id}`, data, {
      headers: await token({
        isFormData: !!data.logo,
      }),
    });
  } else {
    res = await axios.post(`${apiKeysApi}`, data, {
      headers: await token({
        isFormData: !!data.logo,
      }),
    });
  }

  return res;
};

export const deleteApiKeysService = async (id) => {
  const res = await axios.delete(`${apiKeysApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const updateTokenApiKeysService = async (id, data) => {
  const res = await axios.put(`${apiKeysApi}/${id}/update-token`, data, {
    headers: await token({}),
  });
  return res;
};
