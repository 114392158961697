import { Avatar, Chip, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import * as Redux from 'react-redux';
import { getDateFormated } from '../../../helpers';

const DetallesReservas = ({ data }) => {
  const entidadesStore = Redux.useSelector((state) => state.entidades);

  if (!data) return <></>;

  function formatCost(cost) {
    return Number(cost).toFixed(1);
  }

  const isMatchArea = data.subarea.area.name === data.subarea.name;
  const isPrice = data.blockPrice !== 0;
  const isAreaExtras = data.areaExtras.length > 0;

  let totalCostoBloques = 0.0;
  if (isPrice) {
    totalCostoBloques = data.subarea.price * data.totalBlock;
  }

  const extrasWidget = () => {
    const areaExtrasData = (areaExtras) => {
      const extras = [];

      areaExtras.forEach((extra) => {
        const options = extra.options.reduce((acc, option) => {
          const extraOptionId = option.extraOption.id;

          if (!acc[extraOptionId]) {
            acc[extraOptionId] = {
              name: option.extraOption.name,
              values: [],
            };
          }

          acc[extraOptionId].values.push(option.extraOptionValue.value);
          return acc;
        }, {});

        extras.push({
          ...extra,
          options: options,
        });
      });
      console.log(extras);

      return extras;
    };

    return (
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="simple table extras"
        >
          {(isPrice || isAreaExtras) && (
            <TableHead>
              <TableRow>
                <TableCell align="left">Items</TableCell>
                <TableCell align="center">Cantidad</TableCell>
                <TableCell align="right">Costo</TableCell>
                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {isPrice && (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">Bloques Reservados</TableCell>
                <TableCell align="center">{data.totalBlock}</TableCell>
                <TableCell align="right">
                  $ {formatCost(data.subarea.price)}
                </TableCell>
                <TableCell align="right">
                  <strong>$ {formatCost(totalCostoBloques)}</strong>
                </TableCell>
              </TableRow>
            )}
            {areaExtrasData(data.areaExtras).map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">
                  <Stack direction="column" spacing={1}>
                    <Typography variant="body1" fontWeight={600}>
                      {row.extra.name}
                    </Typography>

                    <Stack direction="row" spacing={1}>
                      {row.options &&
                        Object.values(row.options).map((option) => (
                          <Stack
                            key={option.name}
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography variant="body2">
                              {option.name}:
                            </Typography>
                            {option.values.map((value, index) => (
                              <Chip
                                key={index}
                                label={`${value}`}
                                color="primary"
                                size="small"
                              />
                            ))}
                          </Stack>
                        ))}
                    </Stack>
                  </Stack>
                </TableCell>
                <TableCell align="center">{row.quantity}</TableCell>
                <TableCell align="right">$ {formatCost(row.price)}</TableCell>
                <TableCell align="right">
                  <strong>$ {formatCost(row.price * row.quantity)}</strong>
                </TableCell>
              </TableRow>
            ))}
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="right" colSpan={4}>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <strong>Total a pagar</strong>
                  <Typography
                    variant="body1"
                    fontSize={'16pt'}
                    color="initial"
                    textAlign="right"
                  >
                    <strong>$ {formatCost(data.price)}</strong>
                  </Typography>
                </Stack>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const participantsWidget = (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 650 }}
        size="small"
        aria-label="simple table extras"
      >
        {(isPrice || isAreaExtras) && (
          <TableHead>
            <TableRow>
              <TableCell align="left">Foto</TableCell>
              <TableCell align="left">Nombre</TableCell>
              <TableCell align="center">Cedula</TableCell>
              <TableCell align="right">Código</TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {data.participants.map((uc) => (
            <TableRow
              key={uc.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">
                <Avatar
                  alt={uc.user.profile.avatar}
                  src={uc.user.profile.avatar}
                />
              </TableCell>
              <TableCell align="left">
                {uc.user.profile.firstName} {uc.user.profile.lastName}
              </TableCell>
              <TableCell align="center">
                {uc.user.profile.typeDocument}-
                {uc.user.profile.identificationNumber}
              </TableCell>
              <TableCell align="right">{uc.code.code}</TableCell>
            </TableRow>
          ))}
          {/* {data.areaExtras.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.extra.name}</TableCell>
              <TableCell align="center">{row.quantity}</TableCell>
              <TableCell align="right">
                $ {formatCost(row.price)}
              </TableCell>
              <TableCell align="right">
                <strong>$ {formatCost(row.price * row.quantity)}</strong>
              </TableCell>
            </TableRow>
          ))} */}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const timezone = entidadesStore.activo.country.timezone;

  const infoUser = (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <strong>Cédula:</strong> {data.userCode.user.profile.typeDocument}
        {'-'}
        {data.userCode.user.profile.identificationNumber}
      </Grid>
      <Grid item xs={12} sm={12}>
        <strong>Nombre y apellido:</strong>{' '}
        {data.userCode.user.profile.firstName}{' '}
        {data.userCode.user.profile.lastName}
      </Grid>

      <Grid item xs={12} sm={12}>
        <strong>Numero de telefono:</strong> {data.userCode.user.profile.phone}
      </Grid>

      <Grid item xs={12} sm={12}>
        <strong>Email:</strong> {data.userCode.user.email}
      </Grid>
      <Grid item xs={12} sm={12}>
        <strong>Código:</strong> {data.userCode.code.code}
      </Grid>
      {data.participantsCount > 1 && (
        <Grid item xs={12} sm={12}>
          <strong>Participantes:</strong> {data.participantsCount}
        </Grid>
      )}
    </Grid>
  );

  const infoReserva = (
    <Grid container>
      {!isMatchArea && (
        <Grid item xs={12} sm={12}>
          <strong>Área:</strong> {data.subarea.area.name}
        </Grid>
      )}
      <Grid item xs={12} sm={12}>
        <strong> {isMatchArea ? 'Área' : 'Sub área'} :</strong>{' '}
        {data.subarea.name}
      </Grid>
      <Grid item xs={12} sm={12}>
        <strong>Fecha:</strong>{' '}
        {getDateFormated(data.entryTime, 'DD-MM-YYYY', timezone)}
      </Grid>
      {data.subarea.area.blockReservation !== 'day' && (
        <>
          <Grid item xs={12} sm={12}>
            <strong>Hora de entrada:</strong>{' '}
            {getDateFormated(data.entryTime, 'HH:mm a', timezone)}
          </Grid>
          <Grid item xs={12} sm={12}>
            <strong>Hora de sálida:</strong>{' '}
            {getDateFormated(data.departureTime, 'HH:mm a', timezone)}
          </Grid>
        </>
      )}
      {isPrice && (
        <Grid item xs={12} sm={12}>
          <strong>Costo del área:</strong> $ {formatCost(data.subarea.price)}
        </Grid>
      )}
      {/* 
      {isPrice && (
        <Grid item xs={12} sm={12}>
          <strong>Total de bloques reservados:</strong> {data.totalBlock}
        </Grid>
      )}
      {isPrice && totalCostoBloques !== 0.0 && (
        <Grid item xs={12} sm={12}>
          <strong>Costo total por bloques:</strong> {totalCostoBloques}
        </Grid>
      )} */}
    </Grid>
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <Avatar
          alt={data.userCode.user.profile.avatar}
          sx={{ width: 80, height: 80 }}
          src={data.userCode.user.profile.avatar}
        />
        {infoUser}
      </Grid>
      <Grid item xs={12} sm={6}>
        {infoReserva}
      </Grid>
      {data.participants.length > 0 && (
        <Grid item xs={12} sm={12}>
          <strong>Participantes:</strong>
          {participantsWidget}
        </Grid>
      )}

      {(isPrice || isAreaExtras) && (
        <Grid item xs={12} sm={12} sx={{ marginTop: 2 }}>
          {extrasWidget()}
        </Grid>
      )}
      {/* <Grid item xs={12} sm={12}>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </Grid> */}
    </Grid>
  );
};

export default DetallesReservas;
