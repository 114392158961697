import React from 'react';
import ContainerTemplate from '../../components/template/Container';
import * as ROUTES from '../../router/Routes';
import PreciosCustomView from './PreciosCustomView';

const PreciosCustomModule = () => {
  return (
    <ContainerTemplate active={ROUTES.preciosCustom} routes={[]}>
      <PreciosCustomView />
    </ContainerTemplate>
  );
};

export default PreciosCustomModule;
