import { Grid } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Modal } from '../../components';
import { SU } from '../../constants/ConfigConst';
import { getAllAreasAction } from '../areas/AreasAction';
import {
  checkModuleAccess,
  isManagerUserCode,
} from '../modulos/modules.helpers';
import FormPreciosCustom from './components/FormPreciosCustom';
import ListadoPreciosCustom from './components/ListadoPreciosCustom';

const PreciosCustomModule = () => {
  const dispatch = Redux.useDispatch();
  let history = useHistory();

  const loginStore = Redux.useSelector((state) => state.login);
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const areasStore = Redux.useSelector((state) => state.areas);
  const preciosCustomStore = Redux.useSelector((state) => state.preciosCustom);

  const isRoot = loginStore.user.roles.some((r) => r.name === SU);
  const isManager =
    !isRoot &&
    isManagerUserCode(loginStore.user.userCodes, entidadesStore.activo?.id);
  const accessName = 'reservation';
  if (!checkModuleAccess(accessName, loginStore)) {
    window.location.href = '/dashboard';
  }

  const [isMobile, setIsMobile] = React.useState(false);
  const [openModalForm, setOpenModalForm] = React.useState(false);

  React.useEffect(async () => {
    if (areasStore.all && areasStore.all.length === 0) {
      await getAllAreasAction(dispatch, {
        entityId: entidadesStore.activo.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item md={12}></Grid>
      <Grid item md={12}>
        <ListadoPreciosCustom />
      </Grid>
    </Grid>
  );
};

export default PreciosCustomModule;
