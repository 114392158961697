import { Box, Button, Chip, DialogActions, DialogContent, DialogContentText, Grid, Tooltip, Typography } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import * as Redux from 'react-redux';
import ActionsList from '../../../components/ActionsList';
import LoadingComponent from '../../../components/Loading';
import { CYAN_COLOR, MORADO_COLOR } from '../../../constants/ColorsConst';
import * as CONST from '../../../constants/FieldsConst';
import { textLabelsTable } from '../../invitados/components/ListadoInvitados';
import { NameComponent } from '../../usuarios/components/ListadoUsuarios';
import { deleteApiKeysAction, getAllApiKeysAction, saveApiKeysAction, setActivoApiKeysAction, updateTokenApiKeysAction } from '../ApiKeysAction';
import { copyToClipboard } from '../../../helpers';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useNotification } from '../../../helpers/notification';
import { Modal } from '../../../components';

const ListadoApiKeys = () => {
  const dispatch = Redux.useDispatch();

  const apiKeysStore = Redux.useSelector((state) => state.apiKeys);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [loadingUpdateToken, setLoadingUpdateToken] = React.useState(false);
  const [loadingToggleActive, setLoadingToggleActive] = React.useState(false);
  const { addNotification } = useNotification();
  const [isDelete, setIsDelete] = React.useState(false);
  const [isUpdateToken, setIsUpdateToken] = React.useState(false);
  const [isToggleActive, setIsToggleActive] = React.useState(false);

  React.useEffect(() => {
    getAllApiKeysAction(dispatch, apiKeysStore.params);
    // if (apiKeysStore.all && apiKeysStore.all.length === 0) {
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleEditClick = async (id) => {
    const recibo = apiKeysStore.all.find((el) => el.id === id);
    await setActivoApiKeysAction(dispatch, recibo);
  };

  const _handleDeleteClick = async (id) => {
    setLoadingDelete(true);
    await deleteApiKeysAction(
      { dispatch, addNotification },
      id,
      apiKeysStore.params
    );
    setActivoApiKeysAction(dispatch, null);
    setIsDelete(false);
    setLoadingDelete(false);
  };

  const _handleUpdateTokenClick = async (id) => {
    setLoadingUpdateToken(true);
    await updateTokenApiKeysAction(
      { dispatch, addNotification },
      id,
      apiKeysStore.params
    );
    setActivoApiKeysAction(dispatch, null);
    setIsUpdateToken(false);
    setLoadingUpdateToken(false);
  };

  const _handleToggleActiveClick = async (id) => {
    setLoadingToggleActive(true);
    await saveApiKeysAction(
      { dispatch, addNotification },
      id,
      { active: !apiKeysStore.activo.active },
      apiKeysStore.params
    );
    setActivoApiKeysAction(dispatch, null);
    setIsToggleActive(false);
    setLoadingToggleActive(false);
  };

  const columns = [
    {
      name: CONST.ID,
      options: {
        display: 'excluded',
      },
    },
    {
      name: CONST.NAME,
      label: 'Nombre',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            // <NameComponent
            //   onClick={() => _handleEditClick(tableMeta.rowData[0])}
            // >
            //   {value}
            // </NameComponent>
            <Typography>{value}</Typography>
          );
        },
      },
    },
    {
      name: CONST.ACTIVE,
      label: 'Estatus',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip
              describeChild
              title="Estatus del registro"
              placement="left"
            >
              <Chip
                label={value ? 'Activo' : 'Bloqueado'}
                size="small"
                sx={{
                  backgroundColor: value ? CYAN_COLOR : MORADO_COLOR,
                  color: 'white',
                }}
              />
            </Tooltip>
          );
        },
      },
    },
    {
      name: 'token',
      label: 'Token',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Button  onClick={() => copyToClipboard(value)}>{value?.slice(0, 5)}*********{value?.slice(-5)}</Button>;
        },
      },
    },
    {
      name: CONST.ACTIONS,
      label: 'Acción',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const data = apiKeysStore.all.find((el) => el.id === tableMeta.rowData[0]);
          return (
            <>
              <Box align="left">
                <ActionsList
                  id={tableMeta.rowData[0]}
                  onDelete={() => {
                    setActivoApiKeysAction(dispatch, data);
                    setIsDelete(true);
                  }}
                  onUpdateToken={() => {
                    setActivoApiKeysAction(dispatch, data);
                    setIsUpdateToken(true);
                  }}
                  onCopyClipboard={() => {
                    copyToClipboard(data.token);
                  }}
                  onToggleActive={async () => {
                    await setActivoApiKeysAction(dispatch, data);
                    setIsToggleActive(true);
                  }}
                  active={data.active}
                />
              </Box>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: false,
    textLabels: textLabelsTable,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return <Grid container></Grid>;
    },
    selectableRowsHideCheckboxes: true,
  };

  return (
    <Grid container>
      <LoadingComponent
        isLoading={apiKeysStore.loadingGetAll || loadingDelete || loadingToggleActive || loadingUpdateToken}
        text="Cargando lista..."
      />
      <Grid item md={12}>
        <MUIDataTable
          title={'Listado'}
          data={apiKeysStore.all}
          columns={columns}
          options={options}
        />
      </Grid>
      
      {apiKeysStore.activo ? (
        <Modal
          id="modalEliminarApiKey"
          title={`Eliminar ${apiKeysStore.activo.name}`}
          open={isDelete}
          maxWidth="sm"
          onClose={() => setIsDelete(!isDelete)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres eliminar esta api key?
              <p>
                <strong>{apiKeysStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleDeleteClick(apiKeysStore.activo.id);
              }}
            >
              ELIMINAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsDelete(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}

{apiKeysStore.activo ? (
        <Modal
          id="modalUpdateTokenApiKey"
          title={`Actualizar token ${apiKeysStore.activo.name}`}
          open={isUpdateToken}
          maxWidth="sm"
          onClose={() => setIsUpdateToken(!isUpdateToken)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres actualizar el token de esta api key?
              <p>
                <strong>{apiKeysStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleUpdateTokenClick(apiKeysStore.activo.id);
              }}
            >
              ACTUALIZAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsUpdateToken(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}

      {apiKeysStore.activo ? (
        <Modal
          id="modalToggleActiveApiKey"
          title={`${apiKeysStore.activo.active ? 'Bloquear' : 'Activar'} ${
            apiKeysStore.activo.name
          }`}
          open={isToggleActive}
          maxWidth="sm"
          onClose={() => setIsToggleActive(!isToggleActive)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {`¿Seguro que quieres ${
                apiKeysStore.activo.active ? 'Bloquear' : 'Activar'
              } esta api key?`}
              <p>
                <strong>{apiKeysStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleToggleActiveClick(apiKeysStore.activo.id);
              }}
            >
              {apiKeysStore.activo.active ? 'BLOQUEAR' : 'ACTIVAR'}
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsToggleActive(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}
    </Grid>
  );
};

export default ListadoApiKeys;
