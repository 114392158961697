import { tryCatchHelper } from '../../helpers';
import {
  GETALL_MOROSIDAD,
  LOADING_GETALL_MOROSIDAD,
  SET_ACTIVO_MOROSIDAD,
} from '../../store/Types';
import {
  getAllMorosidadService,
  saveMorosidadService,
} from './MorosidadService';

export const getAllMorosidadAction = async (dispatch, params) => {
  try {
    dispatch({
      type: LOADING_GETALL_MOROSIDAD,
      payload: true,
    });
    const res = await getAllMorosidadService(params);
    dispatch({
      type: GETALL_MOROSIDAD,
      payload: res.data,
    });
    dispatch({
      type: LOADING_GETALL_MOROSIDAD,
      payload: false,
    });
    return res.data;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_MOROSIDAD,
      payload: false,
    });
    tryCatchHelper(e);
    return false;
  }
};

export const saveMorosidadAction = async (
  { dispatch, addNotification },
  id,
  data,
  params
) => {
  try {
    let res = await saveMorosidadService(id, data, params);
    addNotification(
      res.data.message ?? id ? 'Banco actualizado.' : 'Banco registrado.'
    );
    await getAllMorosidadAction(dispatch, params);
    setActivoMorosidadAction(dispatch, null);
    return res;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoMorosidadAction = async (dispatch, data) => {
  dispatch({
    type: SET_ACTIVO_MOROSIDAD,
    payload: data,
  });
};
