import {
  ACTIVO_AREAS,
  CLEAR_ALL,
  GETALL_AREAS,
  LOADING_GETALL_AREAS,
  PARAMS_AREAS,
  SET_LOGOUT,
} from '../../store/Types';

const initialState = {
  all: [],
  loadingGetAll: false,
  meta: null,
  links: null,
  activo: null,
  params: {},
};

export const areasReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_AREAS:
      return {
        ...state,
        all: action.payload.data.sort((a, b) => a.name.localeCompare(b.name)),
        links: action.payload.links,
        meta: action.payload.meta,
      };
    case LOADING_GETALL_AREAS:
      return {
        ...state,
        loadingGetAll: action.payload,
      };
    case ACTIVO_AREAS:
      return {
        ...state,
        activo: action.payload,
      };
    case PARAMS_AREAS:
      return {
        ...state,
        params: action.payload,
      };
    case CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };
    case SET_LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
