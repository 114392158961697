import React from 'react';
import ContainerTemplate from '../../components/template/Container';
import * as ROUTES from '../../router/Routes';
import TiposNivelCodeView from './TiposNivelCodeView';

const TiposNivelCodePage = () => {
  return (
    <ContainerTemplate active={ROUTES.tiposNivelCode} routes={[]}>
      <TiposNivelCodeView />
    </ContainerTemplate>
  );
};

export default TiposNivelCodePage;
