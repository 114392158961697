import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../components';
import BotonNuevoComponent from '../entidades/components/BotonNuevo';
import { checkModuleAccess } from '../modulos/modules.helpers';
import FormTiposNivelCode from './components/FormTiposNivelCode';
import ListadoTiposNivelCode from './components/ListadoTiposNivelCode';
import {
  getAllTiposNivelCodeAction,
  setActivoTiposNivelCodeAction,
} from './TiposNivelCodeAction';

const TiposNivelCodeView = () => {
  const dispatch = Redux.useDispatch();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const tiposNivelCodeStore = Redux.useSelector(
    (state) => state.tiposNivelCode
  );

  const loginStore = Redux.useSelector((state) => state.login);
  const accessName = 'invitation';
  if (!checkModuleAccess(accessName, loginStore)) {
    window.location.href = '/dashboard';
  }

  const [openModalForm, setOpenModalForm] = React.useState(false);

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <BotonNuevoComponent
          click={async () => {
            await setActivoTiposNivelCodeAction(dispatch, null);
            setOpenModalForm(true);
          }}
          azul
          mr={2}
        />
        {tiposNivelCodeStore.all.length > 0 && (
          <BotonNuevoComponent
            click={async () => {
              await setActivoTiposNivelCodeAction(dispatch, null);
              await getAllTiposNivelCodeAction(
                dispatch,
                entidadesStore.activo.id,
                null
              );
            }}
            disabled={tiposNivelCodeStore.loadingGetAll}
            white
            text={
              tiposNivelCodeStore.loadingGetAll
                ? 'Actualizando...'
                : 'Actualizar'
            }
          />
        )}
      </Grid>
      <Grid item md={12}>
        <ListadoTiposNivelCode
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Grid>
      <Modal
        id="modalFormTiposNivelCode"
        title={`${
          tiposNivelCodeStore.activo ? 'Editar' : 'Nuevo'
        } tipo de nivel de código`}
        open={openModalForm}
        maxWidth="sm"
        onClose={() => setOpenModalForm(!openModalForm)}
      >
        {tiposNivelCodeStore.activo && (
          <Typography variant="h6">
            {tiposNivelCodeStore.activo.nombres}{' '}
            {tiposNivelCodeStore.activo.apellidos}
          </Typography>
        )}
        <FormTiposNivelCode
          activo={tiposNivelCodeStore.activo}
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Modal>
    </Grid>
  );
};

export default TiposNivelCodeView;
