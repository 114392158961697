import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, styled, Paper, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import dayjs from 'dayjs';
import { formatTime, parseTime } from '../helpers';

// Estilos reutilizables
const TimeDisplay = styled(Typography)(({ theme }) => ({
  width: '100px',
  textAlign: 'center',
  padding: theme.spacing(1),
  fontSize: '1.1rem',
  fontWeight: 'medium',
  color: theme.palette.text.primary,
}));

const TimeInputContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
}));

const ArrowButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: 0,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const TimeInput = ({ 
  value, 
  onChange, 
  interval, 
  minTime, 
  maxTime,
  use12Hours = false,
  label = 'Hora',
}) => {
  const [time, setTime] = useState(value);
  const [options, setOptions] = useState([]);

  // Sincroniza el estado interno con el valor proporcionado desde props
  useEffect(() => {
    setTime(value);
  }, [value]);


  const buildOptions = () => {
    const options = [];
    let start = dayjs(parseTime(minTime));
    let end = dayjs(parseTime(maxTime));
    if (maxTime === '00:00:00') {
      end = end.add(1, 'day');
    }
    while (start.isSameOrBefore(end)) {
      options.push({
        label: use12Hours ? start.format('hh:mm A') : start.format('HH:mm:ss'),
        value: start.format('HH:mm:ss'),
      });
      start = start.add(interval, 'minute');
    }
    return options;
  }

  useEffect(() => {
    if (interval) {
      setOptions(buildOptions());
    } else {
      setOptions([]);
    }
  }, [minTime, maxTime, interval]);

  return (
    <FormControl fullWidth size="small" margin="normal">
      <InputLabel id={label}>{label}</InputLabel>
      <Select
        value={time}
        label={label}
        size="small"
        onChange={(e) => {
          onChange(e.target.value);
        }}
      >
        {
          options.map((option) => (
            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
};

export default TimeInput;
