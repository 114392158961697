import axios from "axios";
import { baseApi, token } from "../../services/Apis";
import { serialize } from "object-to-formdata";

export const SUB_AREAS = `${baseApi}/subareas`;

export const getAllSubAreasService = async (params) => {
  const res = await axios.get(SUB_AREAS, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveSubAreasService = async (id, data) => {
  const isFile = data.image;
  if (!!isFile) {
    data = serialize(data, {
      nullsAsUndefineds: true,
      allowEmptyArrays: true,
      indices: true,
    })
  }

  let res;
  if (id) {
    res = await axios.put(`${SUB_AREAS}/${id}`, data, {
      headers: await token({
        isFormData: !!data.image,
      }),
    });
  } else {
    res = await axios.post(`${SUB_AREAS}`, data, {
      headers: await token({
        isFormData: !!data.image,
      }),
    });
  }
  return res.data;
};

export const getOneSubAreasService = async (id) => {
  const res = await axios.get(`${SUB_AREAS}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteSubAreasService = async (id) => {
  const res = await axios.delete(`${SUB_AREAS}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const saveChangeSubAreasService = async (data) => {
  const res = await axios.post(`${baseApi}/subarea-schedule-changes`, data, {
    headers: await token({}),
  });
  return res;
};

export const deleteChangeSubAreasService = async (id) => {
  const res = await axios.delete(`${baseApi}/subarea-schedule-changes/${id}`, {
    headers: await token({}),
  });
  return res;
};
