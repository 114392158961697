import {
  Avatar,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';

const TabParticipantesEventos = ({ participants }) => {
  return (
    <div style={{ minHeight: '400px', overflowY: 'auto' }}>
      <TableContainer component={Paper} sx={{ backgroundColor: '#f3f3f3' }}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Avatar</TableCell>
              <TableCell align="left">Nombre</TableCell>
              <TableCell align="left">Teléfono</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Asistió</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {participants?.map((item, index) => {
              return (
                <TableRow
                  key={`row_${index}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left">
                    <Avatar alt={item.avatar} src={item.avatar} />
                  </TableCell>
                  <TableCell align="left">
                    {item.firstName} {item.lastName}
                  </TableCell>
                  <TableCell align="left">{item.phone}</TableCell>
                  <TableCell align="left">{item.email}</TableCell>
                  <TableCell align="left">
                    <Chip
                      label={item.checked ? 'Sí' : 'No'}
                      style={{
                        color: '#fff',
                        backgroundColor: item.attended ? '#4CAF50' : '#F44336',
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TabParticipantesEventos;
