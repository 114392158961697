import {
  CLEAR_ALL,
  GETALL_MOROSIDAD,
  LOADING_GETALL_MOROSIDAD,
  PARAMS_MOROSIDAD,
  SET_ACTIVO_MOROSIDAD,
  SET_LOGOUT,
} from '../../store/Types';

const initialState = {
  all: [],
  meta: null,
  links: null,
  activo: null,
  params: {
    date: null,
    perMonth: true,
  },
  loadingGetAll: false,
};

export const morosidadReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_MOROSIDAD:
      return {
        ...state,
        all: action.payload.data,
        links: action.payload.links,
        meta: action.payload.meta,
      };
    case CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };
    case SET_ACTIVO_MOROSIDAD:
      return {
        ...state,
        activo: action.payload,
      };
    case SET_LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    case LOADING_GETALL_MOROSIDAD:
      return {
        ...state,
        loadingGetAll: action.payload,
      };
    case PARAMS_MOROSIDAD:
      return {
        ...state,
        params: action.payload,
      };
    default:
      return state;
  }
};
