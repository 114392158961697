import { Autocomplete, Button, FormControl, Grid, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import * as Redux from 'react-redux';
// import { saveCodigosAction } from '../CodigosAction';
import {
  DatePicker,
  LocalizationProvider,
  PickersDay,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import * as yup from 'yup';
import LoadingComponent from '../../../../components/Loading';
import { SU } from '../../../../constants/ConfigConst';
import { useNotification } from '../../../../helpers/notification';
import { getAllAreasService } from '../../../areas/AreasService';
import BotonNuevoComponent from '../../../entidades/components/BotonNuevo';
import { getAllSubAreasService } from '../../../subareas/SubAreasService';
import { FechaField } from '../../../usuarios/components/FieldsUsuarios';
import { saveDescuentosService } from '../DescuentosService';
import { saveDescuentosAction } from '../DescuentosAction';
import { getDateTimezone } from '../../../../helpers';
// import { getAllUsuariosCodigosService } from '../../../userCodes/UserCodesService';
// import { saveReservasService } from '../ReservasService';
// import ReservasHours from './ReservaHours';
// import FormUser from '../../usuarios/components/FormUser';
// import AddIcon  from '@mui/icons-material/Add';
// import { Modal } from '../../../components';
// import { getDateTimezone } from '../../../helpers';

const FormDescuento = ({ setOpenModalForm, params, onRefresh }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);
  // const [userCodes, setUserCodes] = React.useState([]);
  const [areas, setAreas] = React.useState([]);
  const [subareas, setSubareas] = React.useState([]);
  const [currentReservations, setCurrentReservations] = React.useState([]);
  const loginStore = Redux.useSelector((state) => state.login);
  const descuentosStore = Redux.useSelector((state) => state.descuentos);
  // const [modalFormUser, setModalFormUser] = React.useState(false);
  // const [userCodeValue, setUserCodeValue] = React.useState(null);
  const entidadesStore = Redux.useSelector((state) => state.entidades);

  const timezone = entidadesStore.activo.country.timezone;
  const utcOffset = entidadesStore.activo.country.utcOffset || 'UTC-04:00';

  const onSubmit = async (values) => {
    try {
      const res = await saveDescuentosAction({dispatch, addNotification}, entidadesStore.activo.id, null, {
        ...values,
        startDate: getDateTimezone(dayjs(values.startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'), timezone),
        endDate: getDateTimezone(dayjs(values.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'), timezone),
      });
      console.log(res);
      if (res) {
        if (onRefresh) onRefresh();
        setOpenModalForm(false);
      }
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        console.error(error.response.data.message);
        addNotification(error.response.data.message, { error: true });
      }
    } finally {
      setLoading(false);
    }
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required('El nombre es requerido'),
    areaIds: yup.array().of(yup.number()).required('El área es requerida'),
    subareaIds: yup.array().of(yup.number()).required('La subárea es requerida'),
    percentage: yup.number().required('El porcentaje es requerido'),
    startDate: yup
      .date()
      .required('La fecha de inicio es requerida')
      .min(
        new Date(dayjs().utcOffset(utcOffset).startOf('day').format()),
        'La fecha de inicio no puede ser menor a hoy'
      ),
    endDate: yup
      .date()
      .required('La fecha de fin es requerida')
      .min(
        new Date(dayjs().utcOffset(utcOffset).startOf('day').format()),
        'La fecha de fin no puede ser menor a hoy'
      )
      .when('startDate', (startDate, schema) => {
        return schema.min(startDate, 'La fecha de fin no puede ser menor a la fecha de inicio');
      }),
  });

  const areaIds = descuentosStore.activo?.subareas?.map((el) => el.area.id) || [];

  const initialValue = {
    name: descuentosStore.activo?.name || '',
    areaIds,
    subareaIds: descuentosStore.activo?.subareas?.map((el) => el.id) || [],
    percentage: descuentosStore.activo?.percentage || 0,
    startDate: new Date(dayjs(descuentosStore.activo?.startDate || undefined).utcOffset(utcOffset).startOf('day').format()),
    endDate: new Date(dayjs(descuentosStore.activo?.endDate || undefined).utcOffset(utcOffset).startOf('day').format()),
  };

  // const getAreas = async (params = {}) => {
  const getSubareas = async (params = {}) => {
    const res = await getAllSubAreasService({
      entityId: entidadesStore.activo.id,
    });
    const data = res.data.data;
    const subareas = data.map((el) => ({
      id: el.id,
      name: el.name,
      imageUrl: el.imageUrl,
      label: `${el.name}${el.price ? ' ($)' : ''}`,
      dates: el.dates,
      openingTime: el.openingTime,
      closingTime: el.closingTime,
      interval: el.interval,
      areaId: el.areaId,
      price: el.price,
    }));
    if (res) setSubareas(subareas);
  };

  const getAreas = async (params = {}) => {
    const res = await getAllAreasService({
      entityId: entidadesStore.activo.id,
    });
    const data = res.data.data;
    const areas = data.map((el) => ({
      id: el.id,
      name: el.name,
      imageUrl: el.imageUrl,
      label: el.name,
      openingTime: el.openingTime,
      closingTime: el.closingTime,
      blockReservation: el.blockReservation,
    }));
    if (res) setAreas(areas);
  };
  const findArea = (id) => areas.find((el) => el.id === id);
  const findSubarea = (id) => subareas.find((el) => el.id === id);

  React.useEffect(() => {
    // getUserCodes();
    getSubareas();
    getAreas();
  }, []);

  return (
    <Box component="form" sx={{}}>
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={true}
      >
        {({ values, setFieldValue, handleSubmit, errors, validateField }) => (
          <Grid container spacing={2}>
            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
            <Grid item xs={12} md={12}>
              <LoadingComponent isLoading={loading} text={'Guardando...'} />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  size="small"
                  label="Nombre"
                  value={values.name}
                  onChange={(e) => {
                    setFieldValue('name', e.target.value);
                    setTimeout(() => {
                      validateField('name');
                    });
                  }}
                  onBlur={() => {
                    validateField('name');
                  }}
                  helperText={errors.name || null}
                  error={!!errors.name}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <Autocomplete
                  fullWidth
                  disableClearable
                  id="combo-box-demo"
                  options={areas}
                  size="small"
                  multiple
                  renderInput={(params) => {
                    return (
                      <Grid>
                        <TextField
                          {...params}
                          label="Área"
                          placeholder="Buscar área"
                          onBlur={() => {
                            validateField('areaIds');
                          }}
                          helperText={errors.areaIds || null}
                          error={!!errors.areaIds}
                        />
                      </Grid>
                    );
                  }}
                  renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                      <li key={key} {...optionProps}>
                        <img
                          style={{
                            width: 50,
                            height: 50,
                            marginRight: 10,
                            borderRadius: '50%',
                            objectFit: 'cover',
                            display: 'block',
                          }}
                          src={option.imageUrl}
                          alt={option.label}
                        />
                        {option.label}
                      </li>
                    );
                  }}
                  key={(option) => option.id}
                  onChange={(e, value) => {
                    const findSubarea = subareas.find(
                      (el) => el.areaId === value?.id
                    );
                    // console.log(value ? [value.id] : []);
                    // setFieldValue('subareaId', null);
                    setFieldValue('areaIds', value.map((el) => el.id));
                    setTimeout(() => {
                      validateField('areaIds');
                    });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <Autocomplete
                  fullWidth
                  disableClearable
                  id="combo-box-subareas"
                  size="small"
                  multiple
                  options={subareas
                    .filter((el) => values.areaIds.includes(el.areaId))
                    .map((el) => el.id)}
                  getOptionLabel={(option) => findSubarea(option)?.label}
                  renderInput={(params) => {
                    return (
                      <Grid>
                        <TextField
                          {...params}
                          label="Subárea"
                          placeholder="Buscar subárea"
                          onBlur={() => {
                            validateField('subareaIds');
                          }}
                          helperText={errors.subareaIds || null}
                          error={!!errors.subareaIds}
                        />
                      </Grid>
                    );
                  }}
                  renderOption={(props, _option, { selected }) => {
                    const option = findSubarea(_option);
                    const { key, ...optionProps } = props;
                    return (
                      <li key={key} {...optionProps}>
                        <img
                          style={{
                            width: 50,
                            height: 50,
                            marginRight: 10,
                            borderRadius: '50%',
                            objectFit: 'cover',
                            display: 'block',
                          }}
                          src={option.imageUrl}
                          alt={option.label}
                        />
                        {option.label}
                      </li>
                    );
                  }}
                  key={(option) => option.id}
                  onChange={(e, value) => {
                    setFieldValue('subareaIds', value);
                    setTimeout(() => {
                      validateField('subareaIds');
                    });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  size="small"
                  label="Porcentaje"
                  type="number"
                  value={values.percentage}
                  onChange={(e) => {
                    setFieldValue('percentage', e.target.value);
                    setTimeout(() => {
                      validateField('percentage');
                    });
                  }}
                  onBlur={() => {
                    validateField('percentage');
                  }}
                  helperText={errors.percentage || null}
                  error={!!errors.percentage}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              
              <LocalizationProvider adapterLocale={'es'} dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Fecha de inicio"
                  value={values.startDate}
                  openTo="day"
                  onChange={(e) => {
                    const date = dayjs(e).tz(timezone).startOf('day');
                    const endDate = dayjs(values.endDate).startOf('day');
                    setFieldValue('startDate', date.toDate());
                    if (date.isAfter(endDate)) {
                      setFieldValue('endDate', date.toDate());
                    }
                    setTimeout(() => {
                      validateField('startDate');
                    });
                  }}
                  disablePast
                  views={['day']}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      size="small"
                      required
                      helperText={errors.startDate || null}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={12}>
              
              <LocalizationProvider adapterLocale={'es'} dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Fecha de fin"
                  value={values.endDate}
                  openTo="day"
                  onChange={(e) => {
                    const date = dayjs(e).tz(timezone).startOf('day');
                    const startDate = dayjs(values.startDate).startOf('day');
                    setFieldValue('endDate', date.toDate());
                    if (date.isBefore(startDate)) {
                      setFieldValue('startDate', date.toDate());
                    }
                    setTimeout(() => {
                      validateField('endDate');
                    });
                  }}
                  disablePast
                  views={['day']}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      size="small"
                      required
                      helperText={errors.endDate || null}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            {/* <ReservasHours
              area={findArea(values.areaId)}
              subarea={findSubarea(values.subareaId)}
              onChange={(reservations) => {
                setFieldValue('reservations', reservations);
              }}
              onChangeCurrenReservations={(reservations) => {
                setCurrentReservations(reservations);
              }}
            /> */}
            <Grid item md={12} align="center" sx={{ m: 2 }}>
              <BotonNuevoComponent
                click={handleSubmit}
                disabled={loading}
                text="GUARDAR"
                morado
              />
            </Grid>
          </Grid>
        )}
      </Formik>
    </Box>
  );
};

export default FormDescuento;
  