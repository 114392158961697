import axios from 'axios';
import { baseApi, token } from '../../../services/Apis';

export const citiesApi = `${baseApi}/cities`;

export const getAllCitiesService = async (params) => {
  const res = await axios.get(citiesApi, {
    headers: await token({}),
    params,
  });
  return res;
};

export const getOneCitiesService = async (id) => {
  const res = await axios.get(`${citiesApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteCitiesService = async (id) => {
  const res = await axios.delete(`${citiesApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};
