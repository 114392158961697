import { CLEAR_ALL, SET_LOGOUT } from '../../../store/Types';

export const GETALL_COUNTRIES = 'GETALL_COUNTRIES';
export const ACTIVO_COUNTRIES = 'ACTIVO_COUNTRIES';
export const LOADING_GETALL_COUNTRIES = 'LOADING_GETALL_COUNTRIES';
export const PARAMS_COUNTRIES = 'PARAMS_COUNTRIES';

const initialState = {
  all: [],
  activo: null,
  loadingAll: false,
  params: {},
};

export const countriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_COUNTRIES:
      return {
        ...state,
        all: action.payload.data,
        links: action.payload.links,
        meta: action.payload.meta,
      };
    case CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };
    case ACTIVO_COUNTRIES:
      return {
        ...state,
        activo: action.payload,
      };
    case LOADING_GETALL_COUNTRIES:
      return {
        ...state,
        loadingAll: action.payload,
      };
    case PARAMS_COUNTRIES:
      return {
        ...state,
        params: action.payload,
      };
    case SET_LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
