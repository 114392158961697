import {
  Button,
  ButtonGroup,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import axios from 'axios';
import dayjs from 'dayjs';
import * as React from 'react';
import * as Redux from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Modal } from '../../../components';
import AlertMsgComponent from '../../../components/AlertMsg';
import LoadingComponent from '../../../components/Loading';
import {
  AZUL_COLOR,
  BLANCO_COLOR,
  MORADO_COLOR,
} from '../../../constants/ColorsConst';
import * as CONST from '../../../constants/FieldsConst';
import { dateformat, getDateFormated, getDateTimezone } from '../../../helpers';
import { useNotification } from '../../../helpers/notification';
import { validFielddHelper } from '../../../helpers/ValidFieldsHelper';
import { baseApi, token } from '../../../services/Apis';
import {
  getAllAreasAction,
  saveReservasEspecialesAction,
  setActivoSubAreasAction,
} from '../../../store/Actions';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { FechaField } from '../../usuarios/components/FieldsUsuarios';

const FormReservasEspeciales = ({
  setOpenModalForm,
  onChangeAreas,
  onChangeDates,
}) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();
  const router = useHistory();

  const reservasEspecialesStore = Redux.useSelector(
    (state) => state.reservasEspeciales
  );
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const areasStore = Redux.useSelector((state) => state.areas);

  const [existData, setExistData] = React.useState([]);
  const [loadingSubAreas, setLoadingSubAreas] = React.useState(false);

  const timezone = entidadesStore.activo.country.timezone;

  const activo = reservasEspecialesStore.activo;

  const initData = {
    [CONST.DESCRIPTION]: '',
    [CONST.DATE_FROM]: dateformat(null, {}),
    [CONST.ENTRY_TIME]: '07:00',
    [CONST.DEPARTURE_TIME]: '20:00',
    [CONST.DATE_TO]: '',
    months: [],
    subareaIds: [],
    areaIds: [],
  };

  const [reserva, setReserva] = React.useState(initData);
  const [modalConfirm, setModalConfirm] = React.useState(false);

  const [dataError, setDataError] = React.useState({
    areaIds: false,
    subareaIds: false,
    [CONST.DESCRIPTION]: false,
    [CONST.ENTRY_TIME]: false,
    [CONST.DEPARTURE_TIME]: false,
    [CONST.ENTRY_TIME_ERROR]: false,
    [CONST.DEPARTURE_TIME_ERROR]: false,
    [CONST.TIME_ERROR_IGUAl]: false,
  });
  const _handleSetDataFieldReserva = (key, value) =>
    setReserva({ ...reserva, [key]: value });

  React.useEffect(() => {
    onChangeDates([dateformat(null, {})]);
    const fetchData = async () => {
      if (areasStore.all && areasStore.all.length === 0) {
        await getAllAreasAction(dispatch, {
          entityId: entidadesStore.activo.id,
        });
      }
      setActivoSubAreasAction(dispatch, null);
    };
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const subareaOptions = areasStore.all
    .filter((el) => {
      return reserva.areaIds.includes(el.id);
    })
    .map((el) => {
      return el.subareas;
    })
    .flat()
    .sort((a, b) => a.name.localeCompare(b.name));

  React.useEffect(() => {
    if (subareaOptions.length > 0 || reserva.areaIds.length === 0) {
      setLoadingSubAreas(false);
    }
  }, [subareaOptions, reserva.areaIds]);

  const _validDataForm = () => {
    let r = {};
    let va = [];
    Object.keys(dataError).forEach((key) => {
      const val = reserva[key];
      const valid =
        val === null ||
        val === '' ||
        val === 0 ||
        (Array.isArray(val) && val.length === 0);
      r[key] = valid;
      va.push(valid);
    });
    setDataError(r);
    return !va.includes(true);
  };

  const [loadingSave, setLoadingSave] = React.useState(false);
  const handleSubmit = async () => {
    setExistData([]);

    const valid = _validDataForm();

    if (tab === 2 && reserva.months.length === 0) {
      addNotification('Falta agregar el mes de la reserva', { warning: true });
    }
    if (tab === 1 && reserva.dateFrom === '') {
      addNotification('Falta agregar el día de la reserva', { warning: true });
    }
    if (valid) {
      const data = {
        reservations:
          tab === 1
            ? [
                {
                  [CONST.DESCRIPTION]: reserva.description,
                  [CONST.ENTRY_TIME]: getDateTimezone(
                    `${reserva.dateFrom} ${reserva.entryTime}`,
                    timezone
                  ),
                  [CONST.DEPARTURE_TIME]: getDateTimezone(
                    `${reserva.dateFrom} ${reserva.departureTime}`,
                    timezone
                  ),
                },
              ]
            : todosDiasMes(),
        // [CONST.SUB_AREA_ID]: reserva.subareaId,
        subareaIds: reserva.subareaIds,
      };

      setLoadingSave(true);
      let res = await saveReservasEspecialesAction(
        { dispatch, addNotification },
        data,
        entidadesStore.activo.id,
        {
          areaId: reservasEspecialesStore.dataSearchAreaId,
          date: dayjs(reservasEspecialesStore.dateSearch)
            .startOf('month')
            .toDate(),
          dateTo: dayjs(reservasEspecialesStore.dateSearch)
            .endOf('month')
            .toDate(),
        }
      );

      if (res) {
        setReserva({
          ...reserva,
          ...initData,
        });

        router.push(`/bloqueos-reservas?areaId=${reserva.areaIds[0]}`);
        setTab(1);
        setOpenModalForm(false);
      }
      setLoadingSave(false);
    }
  };

  const handleLoadExistData = async () => {
    const valid = _validDataForm();
    if (!valid) return;
    const data = await axios.get(`${baseApi}/reservations/check-blocks`, {
      params: {
        subareaIds: reserva.subareaIds,
        reservations:
          tab === 1
            ? [
                {
                  [CONST.DESCRIPTION]: reserva.description,
                  [CONST.ENTRY_TIME]: getDateTimezone(
                    `${reserva.dateFrom} ${reserva.entryTime}`,
                    timezone
                  ),
                  [CONST.DEPARTURE_TIME]: getDateTimezone(
                    `${reserva.dateFrom} ${reserva.departureTime}`,
                    timezone
                  ),
                },
              ]
            : todosDiasMes(),
      },
      headers: await token({}),
    });

    const existData = data.data
      .map((r) => {
        const isReservation = r.type === 'reservation';
        const data = {
          id: r.id,
          title: r.description,
          date: getDateFormated(r.entryTime, 'DD-MM-YYYY', timezone),
          start: getDateFormated(r.entryTime, 'hh:mm A', timezone),
          end: getDateFormated(r.departureTime, 'hh:mm A', timezone),
          type:
            r.type === 'block' && !!r.event
              ? 'Evento'
              : r.type === 'block'
              ? 'Bloqueo'
              : 'Reservación',
          subarea: `${r.subarea.name} - ${r.subarea.area.name}`,
          dateFrom: r.entryTime,
        };

        if (isReservation) {
          data.name = r.userCode
            ? `${r.userCode.user.profile.firstName} ${r.userCode.user.profile.lastName}`
            : null;
          data.code = r.userCode.code.code;
          data.avatar = r.userCode.avatar || r.userCode.user.profile.avatar;
        } else {
          data.title = r.description;
        }

        return data;
      })
      .sort(
        (a, b) =>
          new Date(a.dateFrom).getTime() - new Date(b.dateFrom).getTime()
      );

    if (existData.length) {
      setModalConfirm(true);
      setExistData(existData);
    } else {
      handleSubmit();
    }
  };

  const [isAllDias, setIsAllDias] = React.useState(false);
  const arrayDias = ['0', '1', '2', '3', '4', '5', '6'];
  const [selectGrupoDias, setLSelectGrupoDias] = React.useState([]);
  const setSGD = (dia) => {
    if (selectGrupoDias.includes(dia)) {
      const filter = selectGrupoDias.filter((e) => e !== dia);
      setLSelectGrupoDias(filter);
    } else {
      setLSelectGrupoDias((old) => [...old, dia]);
    }
  };

  React.useEffect(() => {
    if (selectGrupoDias.length === 7) {
      setIsAllDias(true);
    } else {
      setIsAllDias(false);
    }
  }, [selectGrupoDias]);

  function stylex(value) {
    return {
      backgroundColor: value ? AZUL_COLOR : BLANCO_COLOR,
      color: value ? BLANCO_COLOR : AZUL_COLOR,
      border: `1px solid ${AZUL_COLOR}`,
      '&:hover': {
        backgroundColor: value ? AZUL_COLOR : BLANCO_COLOR,
        border: `1px solid ${AZUL_COLOR}`,
      },
      '&:active': {
        backgroundColor: value ? AZUL_COLOR : BLANCO_COLOR,
      },
    };
  }

  const diasArray = ['DOM', 'LUN', 'MAR', 'MIE', 'JUE', 'VIE', 'SAB'];
  const grupoDias = (
    <ButtonGroup size="small" aria-label="small button group">
      {diasArray.map((el, index) => (
        <Button
          key={index}
          variant={
            selectGrupoDias.includes(index.toString())
              ? 'contained'
              : 'outlined'
          }
          onClick={() => setSGD(index.toString())}
          sx={stylex(selectGrupoDias.includes(index.toString()))}
        >
          {el}
        </Button>
      ))}
    </ButtonGroup>
  );

  const [tab, setTab] = React.useState(1);
  const mesesArray = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  const getDisabledMonth = (index) => {
    return dayjs().month() > index;
  };

  // const monthIndex = []

  const selectMes = (
    <Grid item xs={12} md={12}>
      <FormControl size="small" sx={{ width: '100%' }}>
        <InputLabel id="selectMeses">Mes</InputLabel>
        <Select
          labelId="selectMeses"
          size="small"
          value={reserva.months}
          label="Meses"
          multiple
          onChange={(e) => {
            setReserva({ ...reserva, months: e.target.value });
          }}
          id={CONST.DATE_TO}
        >
          {mesesArray.map((el, index) => (
            <MenuItem
              key={index + 1}
              value={index}
              disabled={getDisabledMonth(index)}
            >
              {el}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );

  const botonFecha = (title, num) => (
    <Button
      key={`fechab-${num}`}
      variant={tab === num ? 'contained' : 'outlined'}
      sx={{
        backgroundColor: tab === num ? AZUL_COLOR : BLANCO_COLOR,
        color: tab === num ? BLANCO_COLOR : AZUL_COLOR,
        border: `1px solid ${AZUL_COLOR}`,

        '&:hover': {
          backgroundColor: tab === num ? AZUL_COLOR : BLANCO_COLOR,
          border: `1px solid ${AZUL_COLOR}`,
        },
        '&:active': {
          backgroundColor: tab === num ? AZUL_COLOR : BLANCO_COLOR,
        },
      }}
      onClick={() => {
        setTab(num);
        _handleSetDataFieldReserva(CONST.DATE_FROM, '');
        _handleSetDataFieldReserva(CONST.DATE_TO, null);
        setLSelectGrupoDias([]);
      }}
    >
      {title}
    </Button>
  );

  function getLastDayOfMonth(year, month) {
    let date = new Date(year, month + 1, 0);
    return date.getDate();
  }

  const todosDiasMes = () => {
    const anio = dateformat(null, { format: 'YYYY' });

    const diasFull = reserva.months
      .map((mes) => {
        const ultimoDia = getLastDayOfMonth(anio, mes);
        return selectGrupoDias.map((dia) => {
          var startDate = new Date(anio, mes, 1); // año(2023), mes(3), dia(1)
          var endDate = new Date(anio, mes, ultimoDia);
          var diasX = [];
          for (var d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
            if (d.getDay() === parseInt(dia)) {
              var dayYor = dateformat(`${d}`, {});
              if (d > Date.parse(dateformat(null, {}))) {
                diasX.push(dayYor);
              }
            }
          }
          return diasX;
        });
      })
      .flat();

    let data = [];
    diasFull.forEach((xx) => {
      xx.forEach((yy) => {
        if (isTDia) {
          data.push({
            [CONST.DESCRIPTION]: reserva.description,
            [CONST.ENTRY_TIME]: getDateTimezone(`${yy} 00:00:00`, timezone),
            [CONST.DEPARTURE_TIME]: getDateTimezone(`${yy} 23:59:59`, timezone),
          });
        } else {
          data.push({
            [CONST.DESCRIPTION]: reserva.description,
            [CONST.ENTRY_TIME]: getDateTimezone(
              `${yy} ${reserva.entryTime}:00`,
              timezone
            ),
            [CONST.DEPARTURE_TIME]: getDateTimezone(
              `${yy} ${reserva.departureTime}:00`,
              timezone
            ),
          });
        }
      });
    });
    console.log(data);
    _handleSetDataFieldReserva(CONST.DATE_FROM, diasFull[0][0]);
    return data;
  };

  const [isTDia, setIsTDia] = React.useState(false);

  React.useEffect(() => {
    if (tab === 1) {
      const date = getDateTimezone(
        dayjs(reserva.dateFrom).format('YYYY-MM-DD HH:mm:ss'),
        timezone
      );
      onChangeDates([date]);
    } else {
      const days = [];
      for (const monthIndex of reserva.months) {
        for (const day of selectGrupoDias) {
          const dayIndex = parseInt(day);
          let start = dayjs().month(monthIndex).startOf('month');
          const end = dayjs().month(monthIndex).endOf('month');
          while (start.isBefore(end)) {
            if (start.day() === dayIndex) {
              days.push(
                getDateTimezone(start.format('YYYY-MM-DD HH:mm:ss'), timezone)
              );
            }
            start = start.add(1, 'day');
          }
        }
      }
      const daysResult = days
        .filter((el) => el.getTime() > Date.parse(dateformat(null, {})))
        .sort((a, b) => a - b);
      onChangeDates(daysResult);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, reserva.months, selectGrupoDias, reserva.dateFrom]);

  return (
    <Box component="form" sx={{ p: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <LoadingComponent
            isLoading={loadingSave}
            text={activo ? 'Actualizando...' : 'Guardando...'}
          />
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Nuevo bloqueo
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          {areasStore.loadingGetAll ? (
            <Skeleton variant="rectangular" height={42} />
          ) : (
            <FormControl size="small" fullWidth error={dataError.areaIds}>
              <InputLabel id="selectAreas">Áreas</InputLabel>
              <Select
                labelId="selectAreas"
                size="small"
                value={reserva.areaIds}
                multiple
                label="Areas"
                onChange={(e) => {
                  const data = {
                    ...initData,
                    ...reserva,
                    areaIds: e.target.value,
                  };
                  onChangeAreas(e.target.value);
                  setReserva(data);
                  setLoadingSubAreas(true);
                }}
                id={CONST.AREA_ID}
              >
                {areasStore.all.map((el) => {
                  return (
                    <MenuItem key={`areas-${el.id}`} value={el.id}>
                      {el.name}
                    </MenuItem>
                  );
                })}
              </Select>
              {dataError.areaIds && (
                <FormHelperText>{validFielddHelper(1)}</FormHelperText>
              )}
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          {loadingSubAreas ? (
            <Skeleton
              variant="rectangular"
              height={42}
              sx={{ borderRadius: 1.2 }}
            />
          ) : (
            <FormControl
              size="small"
              sx={{ width: '100%' }}
              error={dataError.subareaIds}
            >
              <InputLabel id="selectSubAreas">Sub áreas</InputLabel>
              <Select
                labelId="selectSubAreas"
                size="small"
                value={reserva.subareaIds}
                label="Sub areas"
                multiple
                onChange={(e) => {
                  const data = {
                    ...reserva,
                    subareaIds: e.target.value,
                  };
                  setReserva(data);
                }}
                id={CONST.SUB_AREA_ID}
              >
                {subareaOptions.map((el) => (
                  <MenuItem key={`subareas-${el.id}`} value={el.id}>
                    {el.name}
                  </MenuItem>
                ))}
              </Select>
              {dataError.subareaIds && (
                <FormHelperText>{validFielddHelper(1)}</FormHelperText>
              )}
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            size="small"
            id={CONST.DESCRIPTION}
            required
            label="Motivo"
            value={reserva.description}
            onChange={(e) =>
              _handleSetDataFieldReserva(CONST.DESCRIPTION, e.target.value)
            }
            helperText={dataError.description ? validFielddHelper(1) : null}
            error={dataError.description}
          />
        </Grid>
        <Grid item xs={12} md={12} align="center">
          <ButtonGroup size="small" aria-label="small button group">
            {botonFecha('Día específico', 1)}
            {botonFecha('Por mes', 2)}
          </ButtonGroup>
        </Grid>

        {tab === 1 && (
          <Grid item xs={12} md={12}>
            <FechaField
              l="Fecha"
              disabledPast={true}
              v={reserva.dateFrom}
              on={(e) => {
                _handleSetDataFieldReserva(CONST.DATE_FROM, dateformat(e, {}));
              }}
            />
          </Grid>
        )}

        {tab === 2 ? (
          <>
            <Grid item xs={12} md={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isAllDias}
                    onChange={(e) => {
                      setIsAllDias(e.target.checked);
                      if (e.target.checked) {
                        setLSelectGrupoDias(arrayDias);
                      } else {
                        setLSelectGrupoDias([]);
                      }
                    }}
                  />
                }
                label="Todos los días"
              />
            </Grid>
            <Grid item xs={12} md={12} align="center">
              {grupoDias}
            </Grid>
            {selectMes}
          </>
        ) : null}

        {tab === 3 ? (
          <Grid item xs={12} md={12}>
            {selectMes}
          </Grid>
        ) : null}

        <>
          {dataError.entryTimeError ||
          dataError.departureTimeError ||
          dataError.timeErrorIgual ? (
            <>
              <AlertMsgComponent
                alert={{
                  severity: 'error',
                  title: dataError.timeErrorIgual
                    ? 'La horas no pueden ser iguales'
                    : `La hora de ${
                        dataError.entryTimeError ? 'entrada' : 'sálida'
                      }  no se encuentra entre los parámetro del sub área`,
                }}
              />
            </>
          ) : null}
          <Grid item xs={12} md={6}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <Typography variant="body">Hora de entrada</Typography>
              <TextField
                size="small"
                type="time"
                value={reserva.entryTime}
                onChange={(e) => {
                  _handleSetDataFieldReserva(CONST.ENTRY_TIME, e.target.value);
                }}
                id={CONST.ENTRY_TIME}
                helperText={
                  dataError.entryTime ? validFielddHelper(1) : null
                  // `min: ${dateformat(areasStore.activo?.openingTime, {
                  //   format: 'hh:mm a',
                  //   complete: true,
                  // })}`
                }
                error={
                  dataError.entryTime ||
                  dataError.entryTimeError ||
                  dataError.timeError
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <Typography variant="body">Hora de salida</Typography>
              <TextField
                size="small"
                type="time"
                value={reserva.departureTime}
                onChange={(e) => {
                  _handleSetDataFieldReserva(
                    CONST.DEPARTURE_TIME,
                    e.target.value
                  );
                }}
                helperText={
                  dataError.departureTime ? validFielddHelper(1) : null
                  // `max: ${dateformat(areasStore.activo?.closingTime, {
                  //     format: 'hh:mm a',
                  //     complete: true,
                  //   })}`
                }
                error={
                  dataError.departureTime ||
                  dataError.departureTimeError ||
                  dataError.timeError
                }
              />
            </FormControl>
          </Grid>
        </>
        {/*!isTDia && subareasStore.activo ? (
      ) : null*/}
        <Grid item md={12} align="center">
          <BotonNuevoComponent
            click={() => handleLoadExistData()}
            text="GUARDAR"
            morado
          />
        </Grid>
      </Grid>

      <Modal
        id="modalToggleActivo"
        title="Bloquear subareas"
        open={modalConfirm}
        maxWidth="md"
        onClose={() => setModalConfirm(false)}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Existen reservas, eventos y/o bloqueos en las fechas seleccionadas,
            ¿desea continuar?
          </DialogContentText>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Motivo/Usuario</TableCell>
                  <TableCell align="left" width={200}>
                    Fecha
                  </TableCell>
                  <TableCell align="left">Subárea</TableCell>
                  <TableCell align="left">Tipo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {existData.map((item, index) => {
                  return (
                    <TableRow
                      key={`row_${index}`}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left">
                        {item.name ? (
                          <Typography variant="body1">
                            {item.name} - {item.code}
                          </Typography>
                        ) : (
                          <Typography variant="body1">{item.title}</Typography>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {item.date}
                        <br />
                        {item.start} - {item.end}
                      </TableCell>
                      <TableCell align="left">{item.subarea}</TableCell>
                      <TableCell align="left">
                        <Chip
                          label={item.type}
                          style={{
                            color: '#fff',
                            backgroundColor:
                              item.type === 'Evento'
                                ? '#FF9800'
                                : item.type === 'Bloqueo'
                                ? '#F44336'
                                : '#4CAF50',
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            onClick={async () => {
              setModalConfirm(false);
              await handleSubmit();
            }}
          >
            ACEPTAR
          </Button>
          <Button
            sx={{ color: MORADO_COLOR }}
            onClick={() => setModalConfirm(false)}
          >
            CANCELAR
          </Button>
        </DialogActions>
      </Modal>
    </Box>
  );
};

export default FormReservasEspeciales;
