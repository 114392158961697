import axios from "axios";
import { token, baseApi } from "../../services/Apis";

export const entidades = `${baseApi}/entities`;

export const getAllEntidadesService = async (params) => {
  const res = await axios.get(entidades, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveEntidadesService = async (data, id) => {
  let res;
  if (id) {

    if (data.newAboutImages && data.newAboutImages.length) {
      const formData = new FormData();

      for (let i = 0; i < data.newAboutImages.length; i++) {
        formData.append(`newAboutImages`, data.newAboutImages[i])
      }
      delete data.newAboutImages;

      if (data.deletedAboutImages && data.deletedAboutImages.length) {
        for (let i = 0; i < data.deletedAboutImages.length; i++) {
          formData.append(`deletedAboutImages[]`, data.deletedAboutImages[i])
        }
        delete data.deletedAboutImages;
      }
      
      if (data.schedules && data.schedules.length) {
        for (let i = 0; i < data.schedules.length; i++) {
          const schedule = data.schedules[i];
          formData.append(`schedules[${i}][day]`, schedule.day)
          if (schedule.schedules && schedule.schedules.length) {
            for (let ii = 0; ii < schedule.schedules.length; ii++) {
              const time = schedule.schedules[ii];
              formData.append(`schedules[${i}][schedules][${ii}]`, time)
            }
          }
        }
        delete data.schedules;
      } else {
        formData.append('schedules[]', '')
      }
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      data = formData;
    } else {
      delete data.file;
    }

    res = await axios.put(`${entidades}/${id}`, data, {
      headers: await token({}),
    });
  } else {
    res = await axios.post(entidades, data, {
      headers: await token({}),
    });
  }
  return res;
};

export const saveImagesEntidadesService = async (data, id) => {
  let res = await axios.put(`${entidades}/${id}/update_images`, data, {
    headers: await token({
      isFormData: true,
    }),
  });
  return res;
};

export const getOneEntidadesService = async (id) => {
  const res = await axios.get(`${entidades}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteEntidadesService = async (id) => {
  const res = await axios.delete(`${entidades}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const saveAssignModulesEntidadesService = async (entityId, data) => {
  let res;
  res = await axios.post(`${entidades}/${entityId}/assign_app_modules`, data, {
    headers: await token({}),
  });
  return res;
};

export const deleteAssignModulesEntidadesService = async (entityId, idMod) => {
  let res;
  res = await axios.delete(
    `${entidades}/${entityId}/unassign_app_module/${idMod}`,
    {
      headers: await token({}),
    }
  );
  return res;
};

export const updateMorosityTimeEntidadesService = async (data) => {
  let res = await axios.put(`${baseApi}/entity-resources/update-many-morosity`, data, {
    headers: await token({}),
  });
  return res;
};
