import {
  Button,
  ButtonGroup,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import axios from 'axios';
import dayjs from 'dayjs';
import * as React from 'react';
import * as Redux from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Modal } from '../../../components';
import AlertMsgComponent from '../../../components/AlertMsg';
import LoadingComponent from '../../../components/Loading';
import {
  AZUL_COLOR,
  BLANCO_COLOR,
  MORADO_COLOR,
} from '../../../constants/ColorsConst';
import * as CONST from '../../../constants/FieldsConst';
import { dateformat, getDateFormated, getDateTimezone } from '../../../helpers';
import { useNotification } from '../../../helpers/notification';
import { validFielddHelper } from '../../../helpers/ValidFieldsHelper';
import { baseApi, token } from '../../../services/Apis';
import {
  getAllAreasAction,
  savePreciosCustomAction,
  setActivoSubAreasAction,
} from '../../../store/Actions';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { FechaField } from '../../usuarios/components/FieldsUsuarios';

const FormPreciosCustom = ({
  setOpenModalForm,
  onRefresh,
  onChangeAreas,
  onChangeDates,
}) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const areasStore = Redux.useSelector((state) => state.areas);
  const subareasStore = Redux.useSelector((state) => state.subareas);

  const [isLoadingExistData, setIsLoadingExistData] = React.useState(false);
  const [existData, setExistData] = React.useState([]);

  const preciosCustomStore = Redux.useSelector((state) => state.preciosCustom);
  const router = useHistory();

  const timezone = entidadesStore.activo.country.timezone;

  const activo = preciosCustomStore.activo;

  const initData = {
    name: '',
    price: 0,
    [CONST.DATE_FROM]: dateformat(null, {}),
    startDate: '07:00',
    endDate: '20:00',
    [CONST.DATE_TO]: '',
    months: [],
    subareaIds: [],
    areaIds: [],
  };

  const [customPrice, setCustomPrice] = React.useState({
    ...initData,
    // [CONST.AREA_ID]: '',
    // [CONST.SUB_AREA_ID]: '',
  });

  const [modalConfirm, setModalConfirm] = React.useState(false);

  const [dataError, setDataError] = React.useState({
    // [CONST.AREA_ID]: false,
    // [CONST.SUB_AREA_ID]: false,
    areaIds: false,
    subareaIds: false,
    name: false,
    startDate: false,
    endDate: false,
    startDateError: false,
    endDateError: false,
    timeErrorIgual: false,
    price: false,
  });
  const _handleSetDataFieldCustomPrice = (key, value) =>
    setCustomPrice({ ...customPrice, [key]: value });

  React.useEffect(() => {
    // onChangeDates([dateformat(null, {})]);
    const fetchData = async () => {
      if (areasStore.all && areasStore.all.length === 0) {
        await getAllAreasAction(dispatch, {
          entityId: entidadesStore.activo.id,
        });
      }
      setActivoSubAreasAction(dispatch, null);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loadingSubAreas, setLoadingSubAreas] = React.useState(false);

  const subareaOptions = areasStore.all
    .filter((el) => {
      return customPrice.areaIds.includes(el.id);
    })
    .map((el) => {
      return el.subareas;
    })
    .flat();

  // React.useEffect(() => {
  //   const fetchData = async () => {
  //     if (reserva.areaId !== '') {
  //       const area = areasStore.all.find((el) => el.id === reserva.areaId);
  //       if (area.blockReservation !== 'day') {
  //         setReserva({
  //           ...reserva,
  //           [CONST.ENTRY_TIME]: area.openingTime,
  //           [CONST.DEPARTURE_TIME]: area.closingTime,
  //         });
  //       }
  //       setActivoAreasAction(dispatch, area);
  //       setLoadingSubAreas(true);
  //       await getAllSubAreasAction(dispatch, {
  //         areaId: reserva.areaId,
  //       });
  //       setLoadingSubAreas(false);
  //       setDataError({
  //         ...dataError,
  //         [CONST.ENTRY_TIME_ERROR]: false,
  //         [CONST.DEPARTURE_TIME_ERROR]: false,
  //         [CONST.TIME_ERROR_IGUAl]: false,
  //       });
  //       setIsTDia(area.blockReservation === 'day');
  //     }
  //   };
  //   fetchData();
  // }, [reserva.areaId]); // eslint-disable-line react-hooks/exhaustive-deps

  // React.useEffect(() => {
  //   const fetchData = async () => {
  //     if (reserva.subareaId !== '') {
  //       const subareaX = subareasStore.all.find(
  //         (el) => el.id === reserva.subareaId
  //       );
  //       setActivoSubAreasAction(dispatch, subareaX);
  //       if (subareasStore.activo !== null) {
  //         setDataError({
  //           ...dataError,
  //           [CONST.ENTRY_TIME_ERROR]: false,
  //           [CONST.DEPARTURE_TIME_ERROR]: false,
  //           [CONST.TIME_ERROR_IGUAl]: false,
  //         });
  //       }
  //     }
  //   };
  //   fetchData();
  // }, [reserva.subareaId]); // eslint-disable-line react-hooks/exhaustive-deps

  const _validDataForm = () => {
    let r = {};
    let va = [];
    Object.keys(dataError).forEach((key) => {
      const val = customPrice[key];
      const valid =
        val === null ||
        val === '' ||
        val === 0 ||
        (Array.isArray(val) && val.length === 0);
      r[key] = valid;
      va.push(valid);
    });
    setDataError(r);
    return !va.includes(true);
  };

  const [loadingSave, setLoadingSave] = React.useState(false);
  const handleSubmit = async () => {
    setExistData([]);

    const valid = _validDataForm();

    if (tab === 2 && customPrice.months.length === 0) {
      addNotification('Falta agregar el mes de la reserva', { warning: true });
    }
    if (tab === 1 && customPrice.dateFrom === '') {
      addNotification('Falta agregar el día de la reserva', { warning: true });
    }
    if (valid) {
      const data = {
        customPrices:
          tab === 1
            ? [
                {
                  startDate: getDateTimezone(
                    `${customPrice.dateFrom} ${customPrice.startDate}`,
                    timezone
                  ),
                  endDate: getDateTimezone(
                    `${customPrice.dateFrom} ${customPrice.endDate}`,
                    timezone
                  ),
                },
              ]
            : todosDiasMes(),
        // [CONST.SUB_AREA_ID]: reserva.subareaId,
        price: customPrice.price,
        name: customPrice.name,
        subareaIds: customPrice.subareaIds,
      };

      setLoadingSave(true);
      let res = await savePreciosCustomAction(
        { dispatch, addNotification },
        data,
        entidadesStore.activo.id,
        {
          areaId: preciosCustomStore.dataSearchAreaId,
          date: dayjs(preciosCustomStore.dateSearch).startOf('month').toDate(),
          dateTo: dayjs(preciosCustomStore.dateSearch).endOf('month').toDate(),
        }
      );
      const area = areasStore.all.find((el) => el.id === customPrice.areaId);
      if (res) {
        setCustomPrice({
          ...customPrice,
          ...initData,
          // [CONST.ENTRY_TIME]: area.openingTime,
          // [CONST.DEPARTURE_TIME]: area.closingTime,
        });

        router.push(`/precios_personalizados?areaId=${customPrice.areaIds[0]}`);
        setTab(1);
        setOpenModalForm(false);
      }
      setLoadingSave(false);
    }
  };

  const handleLoadExistData = async () => {
    const valid = _validDataForm();
    if (!valid) return;
    const data = await axios.get(`${baseApi}/custom-prices/check-subareas`, {
      params: {
        subareaIds: customPrice.subareaIds,
        customPrices:
          tab === 1
            ? [
                {
                  startDate: getDateTimezone(
                    `${customPrice.dateFrom} ${customPrice.startDate}`,
                    timezone
                  ),
                  endDate: getDateTimezone(
                    `${customPrice.dateFrom} ${customPrice.endDate}`,
                    timezone
                  ),
                },
              ]
            : todosDiasMes(),
      },
      headers: await token({}),
    });

    const existData = data.data
      .map((r) => {
        const data = {
          id: r.id,
          title: r.name,
          date: getDateFormated(r.startDate, 'DD-MM-YYYY', timezone),
          start: getDateFormated(r.startDate, 'hh:mm A', timezone),
          end: getDateFormated(r.endDate, 'hh:mm A', timezone),
          subarea: `${r.subarea.name} - ${r.subarea.area.name}`,
          startDate: r.startDate,
          price: r.price,
        };
        return data;
      })
      .sort(
        (a, b) =>
          new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      );

    if (existData.length) {
      setModalConfirm(true);
      setExistData(existData);
    } else {
      handleSubmit();
    }
  };

  const selectAreas = (
    <FormControl size="small" sx={{ width: '100%' }} error={dataError.areaIds}>
      <InputLabel id="selectAreas">Áreas</InputLabel>
      <Select
        labelId="selectAreas"
        size="small"
        value={customPrice.areaIds}
        multiple
        label="Areas"
        onChange={(e) => {
          console.log(e);
          const data = {
            ...initData,
            ...customPrice,
            areaIds: e.target.value,
          };
          // onChangeAreas(e.target.value);
          setCustomPrice(data);
        }}
        id={CONST.AREA_ID}
      >
        {areasStore.all.map((el) => {
          return (
            <MenuItem key={`areas-${el.id}`} value={el.id}>
              {el.name}
            </MenuItem>
          );
        })}
      </Select>
      {dataError.areaIds ? (
        <FormHelperText>{validFielddHelper(1)}</FormHelperText>
      ) : null}
    </FormControl>
  );

  const selectSubAreas = (
    <FormControl
      size="small"
      sx={{ width: '100%' }}
      error={dataError.subareaIds}
    >
      <InputLabel id="selectSubAreas">Sub áreas</InputLabel>
      <Select
        labelId="selectSubAreas"
        size="small"
        value={customPrice.subareaIds}
        label="Sub areas"
        multiple
        onChange={(e) => {
          const data = {
            ...customPrice,
            subareaIds: e.target.value,
          };
          setCustomPrice(data);
        }}
        id={CONST.SUB_AREA_ID}
      >
        {subareaOptions.map((el) => {
          return (
            <MenuItem key={`subareas-${el.id}`} value={el.id}>
              {el.name}
            </MenuItem>
          );
        })}
      </Select>
      {dataError.subareaIds ? (
        <FormHelperText>{validFielddHelper(1)}</FormHelperText>
      ) : null}
    </FormControl>
  );

  const [isAllDias, setIsAllDias] = React.useState(false);
  const arrayDias = ['0', '1', '2', '3', '4', '5', '6'];
  const [selectGrupoDias, setLSelectGrupoDias] = React.useState([]);
  const setSGD = (dia) => {
    if (selectGrupoDias.includes(dia)) {
      const filter = selectGrupoDias.filter((e) => e !== dia);
      setLSelectGrupoDias(filter);
    } else {
      setLSelectGrupoDias((old) => [...old, dia]);
    }
  };

  React.useEffect(() => {
    if (selectGrupoDias.length === 7) {
      setIsAllDias(true);
    } else {
      setIsAllDias(false);
    }
  }, [selectGrupoDias]);

  function stylex(value) {
    return {
      backgroundColor: value ? AZUL_COLOR : BLANCO_COLOR,
      color: value ? BLANCO_COLOR : AZUL_COLOR,
      border: `1px solid ${AZUL_COLOR}`,
      '&:hover': {
        backgroundColor: value ? AZUL_COLOR : BLANCO_COLOR,
        border: `1px solid ${AZUL_COLOR}`,
      },
      '&:active': {
        backgroundColor: value ? AZUL_COLOR : BLANCO_COLOR,
      },
    };
  }

  const diasArray = ['DOM', 'LUN', 'MAR', 'MIE', 'JUE', 'VIE', 'SAB'];
  const grupoDias = (
    <ButtonGroup size="small" aria-label="small button group">
      {diasArray.map((el, index) => (
        <Button
          key={index}
          variant={
            selectGrupoDias.includes(index.toString())
              ? 'contained'
              : 'outlined'
          }
          onClick={() => setSGD(index.toString())}
          sx={stylex(selectGrupoDias.includes(index.toString()))}
        >
          {el}
        </Button>
      ))}
    </ButtonGroup>
  );

  const [tab, setTab] = React.useState(1);
  const mesesArray = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  const getDisabledMonth = (index) => {
    return dayjs().month() > index;
  };

  // const monthIndex = []

  const selectMes = (
    <Grid item xs={12} md={12}>
      <FormControl size="small" sx={{ width: '100%' }}>
        <InputLabel id="selectMeses">Mes</InputLabel>
        <Select
          labelId="selectMeses"
          size="small"
          value={customPrice.months}
          label="Meses"
          multiple
          onChange={(e) => {
            setCustomPrice({ ...customPrice, months: e.target.value });
          }}
          id={CONST.DATE_TO}
        >
          {mesesArray.map((el, index) => (
            <MenuItem
              key={index + 1}
              value={index}
              disabled={getDisabledMonth(index)}
            >
              {el}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );

  const botonFecha = (title, num) => (
    <Button
      key={`fechab-${num}`}
      variant={tab === num ? 'contained' : 'outlined'}
      sx={{
        backgroundColor: tab === num ? AZUL_COLOR : BLANCO_COLOR,
        color: tab === num ? BLANCO_COLOR : AZUL_COLOR,
        border: `1px solid ${AZUL_COLOR}`,

        '&:hover': {
          backgroundColor: tab === num ? AZUL_COLOR : BLANCO_COLOR,
          border: `1px solid ${AZUL_COLOR}`,
        },
        '&:active': {
          backgroundColor: tab === num ? AZUL_COLOR : BLANCO_COLOR,
        },
      }}
      onClick={() => {
        setTab(num);
        _handleSetDataFieldCustomPrice(CONST.DATE_FROM, '');
        _handleSetDataFieldCustomPrice(CONST.DATE_TO, null);
        setLSelectGrupoDias([]);
      }}
    >
      {title}
    </Button>
  );

  function getLastDayOfMonth(year, month) {
    let date = new Date(year, month + 1, 0);
    return date.getDate();
  }

  const todosDiasMes = () => {
    const anio = dateformat(null, { format: 'YYYY' });

    const diasFull = customPrice.months
      .map((mes) => {
        const ultimoDia = getLastDayOfMonth(anio, mes);
        return selectGrupoDias.map((dia) => {
          var startDate = new Date(anio, mes, 1); // año(2023), mes(3), dia(1)
          var endDate = new Date(anio, mes, ultimoDia);
          var diasX = [];
          for (var d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
            if (d.getDay() === parseInt(dia)) {
              var dayYor = dateformat(`${d}`, {});
              if (d > Date.parse(dateformat(null, {}))) {
                diasX.push(dayYor);
              }
            }
          }
          return diasX;
        });
      })
      .flat();

    let data = [];
    diasFull.forEach((xx) => {
      xx.forEach((yy) => {
        if (isTDia) {
          data.push({
            startDate: getDateTimezone(`${yy} 00:00:00`, timezone),
            endDate: getDateTimezone(`${yy} 23:59:59`, timezone),
          });
        } else {
          data.push({
            startDate: getDateTimezone(
              `${yy} ${customPrice.startDate}:00`,
              timezone
            ),
            endDate: getDateTimezone(`${yy} ${customPrice.endDate}:00`, timezone),
          });
        }
      });
    });
    console.log(data);
    _handleSetDataFieldCustomPrice(CONST.DATE_FROM, diasFull[0][0]);
    return data;
  };

  const [isTDia, setIsTDia] = React.useState(false);
  // React.useEffect(() => {
  //   if (subareasStore.activo === null) return;
  //   const hoA = dateformat(areasStore.activo?.openingTime, {
  //     isHHmm: true,
  //     complete: true,
  //   });
  //   const hcA = dateformat(areasStore.activo?.closingTime, {
  //     isHHmm: true,
  //     complete: true,
  //   });
  //   const xe = dateformat(reserva.entryTime, {
  //     isHHmm: true,
  //     complete: true,
  //   });
  //   const xd = dateformat(reserva.departureTime, {
  //     isHHmm: true,
  //     complete: true,
  //   });
  //   setDataError({
  //     ...dataError,
  //     [CONST.ENTRY_TIME_ERROR]: xe < hoA || xe > hcA,
  //     [CONST.TIME_ERROR_IGUAl]: xe === xd,
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [reserva.entryTime]);

  // React.useEffect(() => {
  //   if (subareasStore.activo === null) return;
  //   const hoA = dateformat(subareasStore.activo?.openingTime, {
  //     isHHmm: true,
  //     complete: true,
  //   });
  //   const hcA = dateformat(subareasStore.activo.closingTime, {
  //     isHHmm: true,
  //     complete: true,
  //   });
  //   const xe = dateformat(reserva.entryTime, {
  //     isHHmm: true,
  //     complete: true,
  //   });
  //   const xd = dateformat(reserva.departureTime, {
  //     isHHmm: true,
  //     complete: true,
  //   });
  //   setDataError({
  //     ...dataError,
  //     [CONST.DEPARTURE_TIME_ERROR]: xd < hoA || xd > hcA,
  //     [CONST.TIME_ERROR_IGUAl]: xe === xd,
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [reserva.departureTime]);

  React.useEffect(() => {
    if (tab === 1) {
      const date = getDateTimezone(
        dayjs(customPrice.dateFrom).format('YYYY-MM-DD HH:mm:ss'),
        timezone
      );
      // onChangeDates([date]);
    } else {
      const days = [];
      for (const monthIndex of customPrice.months) {
        for (const day of selectGrupoDias) {
          const dayIndex = parseInt(day);
          let start = dayjs().month(monthIndex).startOf('month');
          const end = dayjs().month(monthIndex).endOf('month');
          while (start.isBefore(end)) {
            if (start.day() === dayIndex) {
              days.push(
                getDateTimezone(start.format('YYYY-MM-DD HH:mm:ss'), timezone)
              );
            }
            start = start.add(1, 'day');
          }
        }
      }
      const daysResult = days
        .filter((el) => el.getTime() > Date.parse(dateformat(null, {})))
        .sort((a, b) => a - b);
      // onChangeDates(daysResult);
    }
  }, [tab, customPrice.months, selectGrupoDias, customPrice.dateFrom]);

  return (
    <Box component="form" sx={{ p: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <LoadingComponent
            isLoading={loadingSave}
            text={activo ? 'Actualizando...' : 'Guardando...'}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          {areasStore.loadingGetAll ? (
            <Box sx={{ width: '100%', mb: 1 }}>
              <LinearProgress />
            </Box>
          ) : null}
          {selectAreas}
        </Grid>
        <Grid item xs={12} md={12}>
          {loadingSubAreas ? (
            <Box sx={{ width: '100%', mb: 1 }}>
              <LinearProgress />
            </Box>
          ) : null}
          {selectSubAreas}
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            size="small"
            id="name"
            required
            label="Motivo"
            value={customPrice.name}
            onChange={(e) =>
              _handleSetDataFieldCustomPrice('name', e.target.value)
            }
            helperText={dataError.name ? validFielddHelper(1) : null}
            error={dataError.name}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            size="small"
            type="number"	
            id="price"
            required
            label="Precio"
            value={customPrice.price}
            onChange={(e) =>
              _handleSetDataFieldCustomPrice('price', e.target.value)
            }
            helperText={dataError.price ? validFielddHelper(1) : null}
            error={dataError.price}
            inputProps={{
              min: 0,
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} align="center">
          <ButtonGroup size="small" aria-label="small button group">
            {botonFecha('Día específico', 1)}
            {botonFecha('Por mes', 2)}
          </ButtonGroup>
        </Grid>
        {tab === 1 ? (
          <Grid item xs={12} md={12}>
            <FechaField
              l="Fecha"
              disabledPast={true}
              v={customPrice.dateFrom}
              on={(e) => {
                _handleSetDataFieldCustomPrice(CONST.DATE_FROM, dateformat(e, {}));
              }}
            />
          </Grid>
        ) : null}
        {tab === 2 ? (
          <>
            <Grid item xs={12} md={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isAllDias}
                    onChange={(e) => {
                      setIsAllDias(e.target.checked);
                      if (e.target.checked) {
                        setLSelectGrupoDias(arrayDias);
                      } else {
                        setLSelectGrupoDias([]);
                      }
                    }}
                  />
                }
                label="Todos los días"
              />
            </Grid>
            <Grid item xs={12} md={12} align="center">
              {grupoDias}
            </Grid>
            {selectMes}
          </>
        ) : null}

        {tab === 3 ? (
          <Grid item xs={12} md={12}>
            {selectMes}
          </Grid>
        ) : null}

        <>
          {dataError.entryTimeError ||
          dataError.departureTimeError ||
          dataError.timeErrorIgual ? (
            <>
              <AlertMsgComponent
                alert={{
                  severity: 'error',
                  title: dataError.timeErrorIgual
                    ? 'La horas no pueden ser iguales'
                    : `La hora de ${
                        dataError.entryTimeError ? 'entrada' : 'sálida'
                      }  no se encuentra entre los parámetro del sub área`,
                }}
              />
            </>
          ) : null}
          <Grid item xs={12} md={6}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <Typography variant="body">Hora de entrada</Typography>
              <TextField
                size="small"
                type="time"
                value={customPrice.startDate}
                onChange={(e) => {
                  _handleSetDataFieldCustomPrice('startDate', e.target.value);
                }}
                id="startDate"
                helperText={
                  dataError.startDate ? validFielddHelper(1) : null
                  // `min: ${dateformat(areasStore.activo?.openingTime, {
                  //   format: 'hh:mm a',
                  //   complete: true,
                  // })}`
                }
                error={
                  dataError.startDate ||
                  dataError.startDateError ||
                  dataError.timeError
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <Typography variant="body">Hora de salida</Typography>
              <TextField
                size="small"
                type="time"
                value={customPrice.endDate}
                onChange={(e) => {
                  _handleSetDataFieldCustomPrice('endDate', e.target.value);
                }}
                helperText={
                  dataError.endDate ? validFielddHelper(1) : null
                  // `max: ${dateformat(areasStore.activo?.closingTime, {
                  //     format: 'hh:mm a',
                  //     complete: true,
                  //   })}`
                }
                error={
                  dataError.endDate ||
                  dataError.endDateError ||
                  dataError.timeError
                }
              />
            </FormControl>
          </Grid>
        </>
        {/*!isTDia && subareasStore.activo ? (
      ) : null*/}
        <Grid item md={12} align="center">
          <BotonNuevoComponent
            click={() => handleLoadExistData()}
            text="GUARDAR"
            morado
          />
        </Grid>
      </Grid>

      <Modal
        id="modalToggleActivo"
        title="Precios existentes"
        open={modalConfirm}
        maxWidth="md"
        onClose={() => setModalConfirm(false)}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Existen precios creados para las fechas seleccionadas,
            ¿desea continuar?
          </DialogContentText>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Motivo</TableCell>
                  <TableCell align="left" width={120}>Precio</TableCell>
                  <TableCell align="left" width={200}>Fecha</TableCell>
                  <TableCell align="left">Subárea</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {existData.map((item, index) => {
                  return (
                    <TableRow
                      key={`row_${index}`}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left">
                        <Typography variant="body1">
                          {item.title}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">{item.price}</TableCell>
                      <TableCell align="left">
                        {item.date}
                        <br />
                        {item.start} - {item.end}
                      </TableCell>
                      <TableCell align="left">{item.subarea}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            onClick={async () => {
              setModalConfirm(false);
              await handleSubmit();
            }}
          >
            ACEPTAR
          </Button>
          <Button
            sx={{ color: MORADO_COLOR }}
            onClick={() => setModalConfirm(false)}
          >
            CANCELAR
          </Button>
        </DialogActions>
      </Modal>
    </Box>
  );
};

export default FormPreciosCustom;
