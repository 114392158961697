import {
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../../components';
import ActionsList from '../../../components/ActionsList';
import LoadingComponent from '../../../components/Loading';
import {
  AZUL_COLOR,
  MORADO_COLOR,
  NEGRO_COLOR,
} from '../../../constants/ColorsConst';
import {
  TYPE_ENTITY_ENUM,
  getCodeType,
  getLabelTypeEntity,
} from '../../../constants/enumsConst';
import * as CONST from '../../../constants/FieldsConst';
import { downloadExcelFile } from '../../../helpers/exportHelper';
import { useNotification } from '../../../helpers/notification';
import {
  deleteCodigosAction,
  deletePuertaCodigoAction,
  editPuertaCodigoAction,
  getAllCodigosAction,
  getAllPuertasAction,
  savePuertaCodigoAction,
  setActivoCodigosAction,
} from '../../../store/Actions';
import { GETALL_CODIGOS } from '../../../store/Types';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { textLabelsTable } from '../../invitados/components/ListadoInvitados';
import { accessTypeAllowedLabel } from '../../puertas/enum/access-type-allowed.enum';
import { NameComponent } from '../../usuarios/components/ListadoUsuarios';
import { codigosApi, updateCodeService } from '../CodigosService';

let intervalPage = null;
let intervalSearch = null;

const ListadoCodigos = ({
  setOpenModalForm,
  pagination,
  setPagination,
  params,
}) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const codigosStore = Redux.useSelector((state) => state.codigos);
  const puertasStore = Redux.useSelector((state) => state.puertas);

  const [rows, setRows] = React.useState([]);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [loadingAsignarPuerta, setLoadingAsignarPuerta] = React.useState(false);
  const [modelAssignCode, setModelAssignCode] = React.useState(false);
  const [puerta, setPuerta] = React.useState('9999999');
  const [accessTypeAllowed, setAccessTypeAllowed] = React.useState([]);
  const [accessDoorCodeId, setAccessDoorCodeId] = React.useState(null);

  const isClub = entidadesStore.activo?.entityType.id === 2;
  const [loadingRows, setLoadingRows] = React.useState([]);

  const isPublicEntity = entidadesStore.activo?.isPublic;

  const setLoadRow = (id) => {
    setLoadingRows((old) => [...old, id]);
  };

  const removeLoadRow = (id) => {
    setLoadingRows((old) => old.filter((el) => el !== id));
  };

  const updateCodeStore = (code) => {
    const all = JSON.parse(JSON.stringify(codigosStore.all));
    const index = all.findIndex((el) => el.id === code.id);
    all[index].status = code.status;
    all[index].active = code.active;
    dispatch({
      type: GETALL_CODIGOS,
      payload: {
        data: all,
      },
    });
  };

  React.useEffect(() => {
    if (puerta) {
      const data = puertasStore.all.find((el) => el.id === puerta);
      if (data && !accessDoorCodeId) {
        setAccessTypeAllowed(data.accessTypeAllowed);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [puerta]);

  // const activeAccessTypeAllowed =

  const updateStatus = async (id, status) => {
    setLoadRow(id);
    try {
      const code = await updateCodeService(id, { status });
      updateCodeStore(code);
      addNotification('actualizado el estatus del código');
    } catch (error) {
      addNotification(
        'Ha ocurrido un error al actualizar el estatus del código',
        { error: true }
      );
    }
    removeLoadRow(id);
  };

  React.useEffect(() => {
    if (codigosStore.all && codigosStore.all.length === 0) {
      getAllCodigosAction(dispatch, params);
    }
    if (puertasStore.all && puertasStore.all.length === 0) {
      getAllPuertasAction(dispatch, entidadesStore.activo.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const data = codigosStore.all.map((el) => {
      return {
        ...el,
        [CONST.ACCESS_DOORS]: el.accessDoors,
        [CONST.CODE_TYPE]: getCodeType(el.codeType, isClub),
        [CONST.IDENTIFICATION_NUMBER]: el.owner
          ? `${el.owner.indentificationNumber}`
          : 'null',
        [CONST.USER]: el.owner
          ? `${el.owner.firstName} ${el.owner.lastName}`
          : 'null',
        [CONST.STATUS]: el.status,
      };
    });
    setRows(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codigosStore.all]);

  const _handleEditClick = async (id) => {
    const code = codigosStore.all.find((el) => el.id === id);
    await setActivoCodigosAction(dispatch, code);
    setOpenModalForm(true);
  };

  const _handleDeleteClick = async (id) => {
    setLoadingDelete(true);
    await deleteCodigosAction({ dispatch, addNotification }, id, params);
    setIsDelete(false);
    setLoadingDelete(false);
  };

  const columns = [
    {
      name: CONST.ID,
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: CONST.CODE,
      label: getLabelTypeEntity(entidadesStore.entityType.name),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NameComponent
              onClick={() => {
                if (tableMeta.rowData[2] === 'Entidad') return;
                _handleEditClick(tableMeta.rowData[0]);
              }}
            >
              {value}
            </NameComponent>
          );
        },
      },
    },
    {
      name: CONST.CODE_TYPE,
      label: 'Tipo',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'level',
      label: 'Nivel',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const data = codigosStore.all.find(
            (el) => el.id === tableMeta.rowData[0]
          );
          return data?.level?.name || '-';
        },
      },
    },
    {
      name: CONST.ACCESS_DOORS,
      label: 'Accesos',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const data = codigosStore.all.find(
            (el) => el.id === tableMeta.rowData[0]
          );
          if (data) {
            return data.accessDoorCodes.length === 0 ? (
              <Chip
                label="No Asignado"
                size="small"
                sx={{
                  backgroundColor: AZUL_COLOR,
                  color: 'white',
                }}
              />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  width: '20em',
                  pt: 1,
                }}
              >
                {data.accessDoorCodes
                  ? data.accessDoorCodes.map((el) => (
                      <Chip
                        key={el.accessDoor.name}
                        label={`${el.accessDoor.name} - (${el.accessTypeAllowed
                          .map((el) => accessTypeAllowedLabel[el])
                          .join(', ')})`}
                        variant="outlined"
                        sx={{
                          color: NEGRO_COLOR,
                          borderColor: NEGRO_COLOR,
                          mr: 1,
                          mb: 1,
                          '&:hover': {
                            borderColor: MORADO_COLOR,
                            color: MORADO_COLOR,
                          },
                        }}
                        onClick={async (e) => {
                          const code = codigosStore.all.find(
                            (el) => el.id === tableMeta.rowData[0]
                          );
                          await setActivoCodigosAction(dispatch, code);
                          setModelAssignCode(true);
                          setAccessDoorCodeId(el.id);
                          setPuerta(el.accessDoor.id);
                          setAccessTypeAllowed(el.accessTypeAllowed);
                        }}
                        onDelete={async () => {
                          await deletePuertaCodigoAction(
                            { dispatch, addNotification },
                            tableMeta.rowData[0],
                            el.accessDoor.id,
                            params
                          );
                        }}
                      />
                    ))
                  : null}
              </Box>
            );
          } else {
            return null;
          }
        },
      },
    },

    {
      name: CONST.STATUS,
      label: 'Estatus',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const data = codigosStore.all.find(
            (el) => el.id === tableMeta.rowData[0]
          );
          // bg color #6a2c90
          const activeStyle = {
            backgroundColor: MORADO_COLOR,
            color: 'white',
          };
          const getStyle = (status) => {
            if (status === data?.status) return activeStyle;
            return {};
          };
          return (
            <>
              {loadingRows.includes(data?.id) ? <LinearProgress /> : null}
              <FormControl sx={{ m: 0.5 }}>
                <ToggleButtonGroup
                  row
                  aria-labelledby="demo-form-control-label-placement"
                  name="position"
                  key={data?.id}
                  defaultValue="top"
                  value={data?.status}
                  onChange={async (e) => updateStatus(data?.id, e.target.value)}
                >
                  <ToggleButton
                    value="ACTIVE"
                    size="small"
                    style={getStyle('ACTIVE')}
                    sx={{ px: 1, py: 0.3 }}
                  >
                    Activo
                  </ToggleButton>
                  <ToggleButton
                    value="BLOCKED"
                    size="small"
                    style={getStyle('BLOCKED')}
                    sx={{ px: 1, py: 0.3 }}
                  >
                    Bloqueado
                  </ToggleButton>
                  {!isPublicEntity && (
                    <ToggleButton
                      value="DEBT"
                      size="small"
                      style={getStyle('DEBT')}
                      sx={{ px: 1, py: 0.3 }}
                    >
                      Deuda
                    </ToggleButton>
                  )}
                </ToggleButtonGroup>
              </FormControl>
            </>
          );
        },
      },
    },
    {
      name: CONST.ACTIONS,
      label: 'Acción',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box
                align="left"
                sx={{ display: 'flex', flexDirection: 'row', width: '5em' }}
              >
                <ActionsList
                  id={tableMeta.rowData[0]}
                  onEdit={() => _handleEditClick(tableMeta.rowData[0])}
                  onAsignarPuerta={async () => {
                    const code = codigosStore.all.find(
                      (el) => el.id === tableMeta.rowData[0]
                    );
                    await setActivoCodigosAction(dispatch, code);
                    setModelAssignCode(true);
                    setPuerta('9999999');
                    setAccessTypeAllowed([]);
                    setAccessDoorCodeId(null);
                  }}
                  onDelete={
                    !isPublicEntity &&
                    (async () => {
                      const code = codigosStore.all.find(
                        (el) => el.id === tableMeta.rowData[0]
                      );
                      await setActivoCodigosAction(dispatch, code);
                      setIsDelete(true);
                    })
                  }
                />
              </Box>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },

    onDownload: () => {
      const url = `${codigosApi}/export`;
      const name = 'Residencias';
      downloadExcelFile(url, params, name, addNotification);
      return false;
    },
    serverSide: true,
    onChangePage: (currentPage) => {
      clearTimeout(intervalPage);
      intervalPage = setTimeout(() => {
        setPagination({
          ...pagination,
          page: currentPage + 1,
        });
      }, 500);
    },
    customSearch: () => false,
    onSearchChange: (searchQuery) => {
      clearTimeout(intervalSearch);
      intervalSearch = setTimeout(() => {
        setPagination({
          ...pagination,
          q: searchQuery,
          page: 1,
        });
      }, 500);
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setPagination({
        ...pagination,
        limit: numberOfRows,
        page: 1,
      });
    },
    rowsPerPage: pagination.limit,
    count: pagination.total,
    page: pagination.page - 1,
    rowsPerPageOptions: [15, 30, 50],
    print: false,
    textLabels: textLabelsTable,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return <Grid container></Grid>;
    },
    selectableRowsHideCheckboxes: true,
    customSort: (data, colIndex, sortDirection, meta) => {
      return data;
    },
    onColumnSortChange: (sort, sortDirection) => {
      if (sort === 'accessDoors') sort = 'accessDoorName';
      setPagination({
        ...pagination,
        page: 1,
        sort,
        sortDirection,
      });
    },
  };

  return (
    <Grid container>
      <LoadingComponent
        isLoading={codigosStore.loadingGetAll || loadingDelete}
        text="Cargando lista..."
      />
      <Grid item md={12}>
        <MUIDataTable
          title={'Listado'}
          data={rows}
          columns={columns}
          options={options}
        />
      </Grid>
      {codigosStore.activo ? (
        <Modal
          id="modalFormCodigos"
          title={`Eliminar código`}
          open={isDelete}
          maxWidth="sm"
          onClose={() => setIsDelete(!isDelete)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres eliminar este regitro?
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '20px',
                }}
              >
                <label>
                  <strong>
                    {TYPE_ENTITY_ENUM[entidadesStore.entityType.name]}:
                  </strong>{' '}
                  {codigosStore.activo.code}
                </label>
                <label>
                  <strong>Tipo de usuario:</strong>{' '}
                  {getCodeType(codigosStore.activo.codeType, isClub)}
                </label>
                <label>
                  <strong>Nivel:</strong> {codigosStore.activo.level?.name}
                </label>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                setIsDelete(true);
                await _handleDeleteClick(codigosStore.activo.id);
              }}
            >
              ELIMINAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsDelete(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}
      {codigosStore.activo ? (
        <Modal
          id="modalFormCodigos"
          title={`${!!accessDoorCodeId ? 'Editar' : 'Asignar'} puerta a ${
            codigosStore.activo.code
          }`}
          open={modelAssignCode}
          maxWidth="sm"
          onClose={() => setModelAssignCode(!modelAssignCode)}
        >
          <DialogContent>
            <LoadingComponent
              isLoading={loadingAsignarPuerta}
              text="Guardando asignación..."
            />
            <DialogContentText id="alert-dialog-slide-description">
              <Grid item xs={12} md={12}>
                <InputLabel id={`${CONST.ACCESS_TYPE_ALLOWED}_select`}>
                  Acceso (Puerta)
                </InputLabel>
                <Select
                  labelId="puertasSelect"
                  size="small"
                  value={puerta}
                  fullWidth
                  disabled={!!accessDoorCodeId}
                  onChange={(e) => {
                    setPuerta(e.target.value);
                  }}
                  id="puertasSelect"
                >
                  <MenuItem value="9999999">Seleccionar una puerta...</MenuItem>
                  {puertasStore.all
                    .filter((el) => {
                      if (accessDoorCodeId) return true;
                      return !codigosStore.activo.accessDoorCodes?.find(
                        (el2) => el2.accessDoor.id === el.id
                      );
                    })
                    .map((e) => {
                      return (
                        <MenuItem key={`puerta${e.id}`} value={e.id}>
                          {e.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </Grid>
              <Grid item xs={12} md={12} style={{ marginTop: '15px' }}>
                <InputLabel id={`${CONST.ACCESS_TYPE_ALLOWED}_select`}>
                  Tipos de accesos permitidos
                </InputLabel>
                <Select
                  labelId={`${CONST.ACCESS_TYPE_ALLOWED}_select`}
                  size="small"
                  multiple={true}
                  fullWidth
                  value={accessTypeAllowed}
                  onChange={(e) => {
                    setAccessTypeAllowed(e.target.value);
                  }}
                  id={CONST.ACCESS_TYPE_ALLOWED}
                >
                  {puertasStore.all
                    .find((el) => el.id === puerta)
                    ?.accessTypeAllowed?.map((el) => {
                      return (
                        <MenuItem key={`accessTypeAllowed${el}`} value={el}>
                          {accessTypeAllowedLabel[el]}
                        </MenuItem>
                      );
                    })}
                </Select>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <BotonNuevoComponent
              click={async () => {
                setLoadingAsignarPuerta(true);
                if (accessDoorCodeId) {
                  await editPuertaCodigoAction(
                    { dispatch, addNotification },
                    accessDoorCodeId,
                    accessTypeAllowed,
                    params
                  );
                } else {
                  await savePuertaCodigoAction(
                    { dispatch, addNotification },
                    codigosStore.activo.id,
                    puerta,
                    accessTypeAllowed,
                    params
                  );
                }

                setPuerta('9999999');
                setAccessTypeAllowed([]);
                setAccessDoorCodeId(null);
                setLoadingAsignarPuerta(false);
                setModelAssignCode(false);
              }}
              disabled={loadingAsignarPuerta || puerta === '9999999'}
              text="GUARDAR"
              morado
            />
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => {
                setLoadingAsignarPuerta(false);

                setPuerta('9999999');
                setAccessTypeAllowed([]);
                setAccessDoorCodeId(null);
                setModelAssignCode(false);
              }}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}
      {/* {modalUsers} */}
    </Grid>
  );
};

export default ListadoCodigos;
