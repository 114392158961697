import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../components';
import { getLabelTypeEntity } from '../../constants/enumsConst';
import { PARAMS_CODIGOS } from '../../store/Types';
import BotonNuevoComponent from '../entidades/components/BotonNuevo';
import { checkModuleAccess } from '../modulos/modules.helpers';
import { getAllCodigosAction, setActivoCodigosAction } from './CodigosAction';
import FormCodigos from './components/FormCodigos';
import ListadoCodigos from './components/ListadoCodigos';

const CodigosView = () => {
  const dispatch = Redux.useDispatch();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const codigosStore = Redux.useSelector((state) => state.codigos);

  const loginStore = Redux.useSelector((state) => state.login);
  const accessName = 'code';
  if (!checkModuleAccess(accessName, loginStore)) {
    window.location.href = '/dashboard';
  }

  const [openModalForm, setOpenModalForm] = React.useState(false);

  const [pagination, setPagination] = React.useState({
    page: 1,
    limit: 15,
    total: 0,
    sort: 'createdAt',
    sortDirection: 'desc',
  });

  React.useEffect(() => {
    _handleGetAll().then(({ data, ...res }) => {
      setPagination({
        ...pagination,
        total: res.total,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.page,
    pagination.limit,
    entidadesStore.activo,
    pagination.q,
    pagination.sort,
    pagination.sortDirection,
  ]);

  const _handleGetAll = async () => {
    const payload = {
      ...params,
      page: pagination.page,
      limit: pagination.limit,
      q: pagination.q,
      sort: pagination.sort,
      sortDirection: pagination.sortDirection,
    };
    setParams(payload);
    dispatch({
      type: PARAMS_CODIGOS,
      payload,
    });
    return await getAllCodigosAction(dispatch, payload);
  };

  const [params, setParams] = React.useState({
    ...codigosStore.params,
    entityId: entidadesStore.activo.id,
    page: pagination.page,
    limit: pagination.limit,
    sort: pagination.sort,
    sortDirection: pagination.sortDirection,
  });

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <BotonNuevoComponent
          click={async () => {
            await setActivoCodigosAction(dispatch, null);
            setOpenModalForm(true);
          }}
          azul
          mr={2}
        />
        {codigosStore.all.length > 0 && (
          <BotonNuevoComponent
            click={async () => {
              await setActivoCodigosAction(dispatch, null);
              await getAllCodigosAction(dispatch, params);
            }}
            white
            disabled={codigosStore.loadingGetAll}
            text={codigosStore.loadingGetAll ? 'Actualizando...' : 'Actualizar'}
          />
        )}
      </Grid>

      <Grid item md={12}>
        <ListadoCodigos
          setOpenModalForm={(val) => setOpenModalForm(val)}
          params={params}
          setParams={setParams}
          pagination={pagination}
          setPagination={setPagination}
        />
      </Grid>

      <Modal
        id="modalFormCodigos"
        title={`${
          codigosStore.activo ? 'Editar' : `Nueva`
        } ${getLabelTypeEntity(entidadesStore.entityType.name)}`}
        open={openModalForm}
        maxWidth="sm"
        onClose={() => setOpenModalForm(!openModalForm)}
      >
        {codigosStore.activo && (
          <Typography variant="h6">
            {codigosStore.activo.nombres} {codigosStore.activo.apellidos}
          </Typography>
        )}
        <FormCodigos
          activo={codigosStore.activo}
          setOpenModalForm={(val) => setOpenModalForm(val)}
          params={params}
        />
      </Modal>
    </Grid>
  );
};

export default CodigosView;
