import { tryCatchHelper } from '../../helpers';
import {
  deleteTiposNivelCodeService,
  getAllTiposNivelCodeService,
  getOneTiposNivelCodeService,
  saveTiposNivelCodeService,
} from '../../services';
import {
  ACTIVO_TIPOS_NIVEL_CODE,
  GETALL_TIPOS_NIVEL_CODE,
  LOADING_GETALL_TIPOS_NIVEL_CODE,
} from '../../store/Types';

export const getAllTiposNivelCodeAction = async (dispatch, entityId) => {
  try {
    dispatch({
      type: LOADING_GETALL_TIPOS_NIVEL_CODE,
      payload: true,
    });
    const res = await getAllTiposNivelCodeService({ entityId });
    if (res.status === 200) {
      dispatch({
        type: GETALL_TIPOS_NIVEL_CODE,
        payload: res.data,
      });
      dispatch({
        type: LOADING_GETALL_TIPOS_NIVEL_CODE,
        payload: false,
      });
      return res.data;
    }
    dispatch({
      type: LOADING_GETALL_TIPOS_NIVEL_CODE,
      payload: false,
    });
    return null;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_TIPOS_NIVEL_CODE,
      payload: false,
    });
    tryCatchHelper(e);
  }
};

export const saveTiposNivelCodeAction = async (
  { dispatch, addNotification },
  entityId,
  id,
  data
) => {
  try {
    const res = await saveTiposNivelCodeService(id, data);
    addNotification(
      res.message ?? id
        ? 'Tipo de invitación actualizada.'
        : 'Tipo de invitación registrada.'
    );
    await getAllTiposNivelCodeAction(dispatch, entityId);
    return res;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoTiposNivelCodeAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_TIPOS_NIVEL_CODE,
    payload: data,
  });
};

export const getOneTiposNivelCodeAction = async (dispatch, id) => {
  try {
    const res = await getOneTiposNivelCodeService(id);
    if (res.status === 200) {
      await setActivoTiposNivelCodeAction(dispatch, res.data);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const deleteTiposNivelCodeAction = async (
  { dispatch, addNotification },
  id,
  entityId
) => {
  try {
    const res = await deleteTiposNivelCodeService(id, entityId);
    if (res.status === 200) {
      addNotification(res.data.message ?? 'Tipo de invitación eliminada.');
      await setActivoTiposNivelCodeAction(dispatch, null);
      await getAllTiposNivelCodeAction(dispatch, entityId, null);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
