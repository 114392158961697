import { Button } from '@mui/material';
import React from 'react';
import {
  AZUL_COLOR,
  BLANCO_COLOR,
  CYAN_COLOR,
  MORADO_COLOR,
  NEGRO_COLOR,
} from '../../../constants/ColorsConst';

const BotonNuevoComponent = ({
  type,
  click,
  text,
  color,
  white,
  morado,
  cyan,
  mr,
  ml,
  mt,
  mb,
  disabled,
  azul,
  variant,
  sx,
}) => {
  let bgColor = white
    ? BLANCO_COLOR
    : morado
    ? MORADO_COLOR
    : cyan
    ? CYAN_COLOR
    : azul
    ? AZUL_COLOR
    : color || CYAN_COLOR;
  return (
    <Button
      type={type}
      variant={variant ?? 'contained'}
      disabled={disabled}
      sx={{
        mr: mr || 0,
        ml: ml || 0,
        mt: mt || 0,
        mb: mb || 0,
        backgroundColor: bgColor,
        color: white ? NEGRO_COLOR : BLANCO_COLOR,
        '&:hover': {
          backgroundColor: bgColor,
        },
        ...sx,
      }}
      size="small"
      onClick={click}
    >
      {text || 'Nuevo'}
    </Button>
  );
};

export default BotonNuevoComponent;
