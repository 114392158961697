import EditIcon from '@mui/icons-material/Edit';
import HouseIcon from '@mui/icons-material/House';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import * as React from 'react';
// import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArticleIcon from '@mui/icons-material/Article';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import SendIcon from '@mui/icons-material/Send';
import SensorDoorIcon from '@mui/icons-material/SensorDoor';
import SettingsIcon from '@mui/icons-material/Settings';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

import { Download, DownloadDone } from '@mui/icons-material';
import { red } from '@mui/material/colors';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[500],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const ActionsList = ({
  id,
  onEdit,
  onSuscripcion,
  onAdministrador,
  onVistas,
  onDetalles,
  onToggleActive,
  onDeletePermanent,
  onAsignarPuerta,
  onAsignarModulos,
  onAsignarCodigo,
  onSendConfirmationEmail,
  onSendCompleteRegistrationEmail,
  onAsignarIot,
  onDownload,
  onSetUp,
  activo,
  active,
  onAssignTunnel,
  onAssignAllCodes,
  disabledDeleted = false,
  onCheckConnection,
  onDelete,
  onUpdateToken,
  onCopyClipboard,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const asignarIotComponent = onAsignarIot ? (
    <MenuItem
      key={`${id}asignarIot`}
      onClick={() => {
        onAsignarIot();
        setAnchorEl(null);
      }}
      disableRipple
    >
      <DeviceUnknownIcon />
      Asignar Iot
    </MenuItem>
  ) : null;

  const editComponent = onEdit ? (
    <MenuItem
      key={`${id}edit`}
      onClick={() => {
        onEdit();
        setAnchorEl(null);
      }}
      disableRipple
    >
      <EditIcon />
      Editar
    </MenuItem>
  ) : null;

  const suscripcionComponent = onSuscripcion ? (
    <MenuItem
      key={`${id}suscription`}
      onClick={() => {
        onSuscripcion();
        setAnchorEl(null);
      }}
      disableRipple
    >
      <WorkspacePremiumIcon />
      Suscripción
    </MenuItem>
  ) : null;

  const administradorComponent = onAdministrador ? (
    <MenuItem
      key={`${id}administrator`}
      onClick={() => {
        onAdministrador();
        setAnchorEl(null);
      }}
      disableRipple
    >
      <AssignmentIndIcon />
      Administrador
    </MenuItem>
  ) : null;

  const assignTunnelComponent = onAssignTunnel ? (
    <MenuItem
      key={`${id}assignTunnel`}
      onClick={() => {
        onAssignTunnel();
        setAnchorEl(null);
      }}
      disableRipple
    >
      <AssignmentIndIcon />
      Asignar Tunel
    </MenuItem>
  ) : null;

  const vistasComponent = onVistas ? (
    <MenuItem
      key={`${id}vistas`}
      onClick={() => {
        onVistas();
        setAnchorEl(null);
      }}
      disableRipple
    >
      <ViewModuleIcon />
      Vistas
    </MenuItem>
  ) : null;

  const deleteComponent = onDelete && (
    <MenuItem
      key={`${id}delete`}
      onClick={() => {
        onDelete();
        setAnchorEl(null);
      }}
      disabled={disabledDeleted}
      disableRipple
      sx={{ color: red[500] }}
    >
      <DeleteIcon sx={{ color: `${red[500]} !important` }} />
      Eliminar
    </MenuItem>
  );

  const toggleActiveComponent = onToggleActive ? (
    <MenuItem
      key={`${id}toggleActive`}
      onClick={() => {
        onToggleActive();
        setAnchorEl(null);
      }}
      disableRipple
    >
      {active ? <HighlightOffIcon /> : <DownloadDone />}
      {active ? 'Bloquear' : 'Activar'}
    </MenuItem>
  ) : null;

  const asignarPuertaComponent = onAsignarPuerta ? (
    <MenuItem
      key={`${id}asignarPuerta`}
      onClick={() => {
        onAsignarPuerta();
        setAnchorEl(null);
      }}
      disableRipple
    >
      <SensorDoorIcon />
      Asignar Puerta
    </MenuItem>
  ) : null;

  const asignarCodigoComponent = onAsignarCodigo ? (
    <MenuItem
      key={`${id}asignarCodigo`}
      onClick={() => {
        onAsignarCodigo();
        setAnchorEl(null);
      }}
      disableRipple
    >
      <HouseIcon />
      Asignar Codigo
    </MenuItem>
  ) : null;

  const sendConfirmationEmailComponent = onSendConfirmationEmail ? (
    <MenuItem
      key={`${id}sendConfirmationEmail`}
      onClick={() => {
        onSendConfirmationEmail();
        setAnchorEl(null);
      }}
      disableRipple
    >
      <SendIcon />
      Enviar confirmación de correo
    </MenuItem>
  ) : null;

  const sendCompleteRegistrationEmailComponent = onSendCompleteRegistrationEmail ? (
    <MenuItem
      key={`${id}sendCompleteRegistrationEmail`}
      onClick={() => {
        onSendCompleteRegistrationEmail();
        setAnchorEl(null);
      }}
      disableRipple
    >
      <SendIcon />
      Enviar correo de completar registro
    </MenuItem>
  ) : null;

  const descargarComponent = onDownload ? (
    <MenuItem
      key={`${id}asignarCodigo`}
      onClick={() => {
        onDownload();
        setAnchorEl(null);
      }}
      disableRipple
    >
      <Download />
      Descargar
    </MenuItem>
  ) : null;

  const setUpComponent = onSetUp ? (
    <MenuItem
      key={`${id}setUp`}
      onClick={() => {
        onSetUp();
        setAnchorEl(null);
      }}
      disableRipple
    >
      <SettingsIcon />
      Configurar
    </MenuItem>
  ) : null;

  const asignarModulosComponent = onAsignarModulos ? (
    <MenuItem
      key={`${id}asignarModulos`}
      onClick={() => {
        onAsignarModulos();
        setAnchorEl(null);
      }}
      disableRipple
    >
      <ViewModuleIcon />
      Asignar Módulos
    </MenuItem>
  ) : null;

  const deletePermanentComponent = onDeletePermanent && (
    <MenuItem
      key={`${id}deletePermanent`}
      onClick={() => {
        onDeletePermanent();
        setAnchorEl(null);
      }}
      disableRipple
      sx={{ color: red[900] }}
    >
      <DeleteForeverIcon sx={{ color: red[900] }} />
      Eliminar permanentemente
    </MenuItem>
  );

  const checkConnection = onCheckConnection ? (
    <MenuItem
      key={`${id}checkIotConnection`}
      onClick={() => {
        onCheckConnection();
        setAnchorEl(null);
      }}
      disableRipple
    >
      <ReplayOutlinedIcon />
      Check Connection
    </MenuItem>
  ) : null;

  const assignAllCodesComponent = onAssignAllCodes ? (
    <MenuItem
      key={`${id}assignAllCodes`}
      onClick={() => {
        onAssignAllCodes();
        setAnchorEl(null);
      }}
      disableRipple
    >
      <FileCopyIcon />
      Asignar todos los códigos
    </MenuItem>
  ) : null;

  const detallesComponent = onDetalles && (
    <MenuItem
      key={`${id}detalles`}
      onClick={() => {
        onDetalles();
        setAnchorEl(null);
      }}
      disableRipple
    >
      <ArticleIcon />
      Detalles
    </MenuItem>
  );

  const updateTokenComponent = onUpdateToken ? (
    <MenuItem
      key={`${id}updateToken`}
      onClick={() => {
        onUpdateToken();
        setAnchorEl(null);
      }}
      disableRipple
    >
      <SettingsIcon />
      Actualizar token
    </MenuItem>
  ) : null;

  const copyClipboardComponent = onCopyClipboard ? (
    <MenuItem
      key={`${id}copyClipboard`}
      onClick={() => {
        onCopyClipboard();
        setAnchorEl(null);
      }}
      disableRipple
    >
      <FileCopyIcon />
      Copiar al portapapeles
    </MenuItem>
  ) : null;

  const menus = [
    editComponent,
    suscripcionComponent,
    administradorComponent,
    detallesComponent,
    vistasComponent,
    asignarPuertaComponent,
    asignarModulosComponent,
    asignarCodigoComponent,
    sendConfirmationEmailComponent,
    sendCompleteRegistrationEmailComponent,
    asignarIotComponent,
    descargarComponent,
    setUpComponent,
    assignTunnelComponent,
    checkConnection,
    assignAllCodesComponent,
    toggleActiveComponent,
    deleteComponent,
    deletePermanentComponent,
    updateTokenComponent,
    copyClipboardComponent,
  ];

  return (
    <div>
      <IconButton
        aria-label="more"
        id={`${id}icon`}
        size="small"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon
          fontSize="small"
          sx={{ color: `${activo ? 'white' : 'balck'}` }}
        />
      </IconButton>
      <StyledMenu
        id={`${id}styled`}
        MenuListProps={{
          'aria-labelledby': id,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {menus.map((e) => e)}
      </StyledMenu>
    </div>
  );
};

export default ActionsList;
