import axios from 'axios';
import { baseApi, token } from '../../../services/Apis';

export const statesApi = `${baseApi}/states`;

export const getAllStatesService = async (params) => {
  const res = await axios.get(statesApi, {
    headers: await token({}),
    params,
  });
  return res;
};

export const getOneStatesService = async (id) => {
  const res = await axios.get(`${statesApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteStatesService = async (id) => {
  const res = await axios.delete(`${statesApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};
