import { tryCatchHelper } from '../../helpers';
import {
  ACTIVO_PRECIOS_CUSTOM,
  GETALL_PRECIOS_CUSTOM,
  LOADING_GETALL_PRECIOS_CUSTOM,
} from '../../store/Types';

import {
  deletePreciosCustomService,
  getAllPreciosCustomService,
  savePreciosCustomService,
} from './PreciosCustomService';

export const getAllPreciosCustomAction = async (dispatch, entityId, params) => {
  try {
    dispatch({
      type: LOADING_GETALL_PRECIOS_CUSTOM,
      payload: true,
    });
    const res = await getAllPreciosCustomService({
      entityId,
      type: 'block',
      groupByBlockId: false,
      ...params,
    });
    dispatch({
      type: GETALL_PRECIOS_CUSTOM,
      payload: res.data.data,
    });
    dispatch({
      type: LOADING_GETALL_PRECIOS_CUSTOM,
      payload: false,
    });
    return res.data;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_PRECIOS_CUSTOM,
      payload: false,
    });
    tryCatchHelper(e);
  }
};

export const savePreciosCustomAction = async (
  { dispatch, addNotification },
  data,
  entityId,
  params
) => {
  try {
    let res = await savePreciosCustomService(data);
    addNotification(res.data.message ?? 'Precios generados.');
    await getAllPreciosCustomAction(dispatch, entityId, params);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoPreciosCustomAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_PRECIOS_CUSTOM,
    payload: data,
  });
};

export const deletePreciosCustomAction = async (
  { dispatch, addNotification },
  entityId,
  id,
  query,
  params
) => {
  try {
    const res = await deletePreciosCustomService(id, query);
    addNotification(res.data.message ?? 'Precio eliminado');
    await setActivoPreciosCustomAction(dispatch, null);
    getAllPreciosCustomAction(dispatch, entityId, params);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
