import { Button, DialogActions, DialogContent } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonFile } from '../../../../components';
import LoadingComponent from '../../../../components/Loading';
import { MORADO_COLOR } from '../../../../constants/ColorsConst';
import { useNotification } from '../../../../helpers/notification';
import BotonNuevoComponent from '../../../entidades/components/BotonNuevo';
import { bulkExcelEventosAction } from '../EventosAction';

const ModalExcelEventos = ({
  loadingExcel,
  setLoadingExcel,
  setFileExcel,
  fileExcel,
  setModalExcel,
  getAllEventosAction,
}) => {
  const dispatch = useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = useSelector((state) => state.entidades);
  const eventosStore = useSelector((state) => state.eventos);

  return (
    <>
      <DialogContent>
        <LoadingComponent
          isLoading={loadingExcel}
          text="Procensando archivo excel..."
        />
        <ButtonFile
          id={'archivoexcel'}
          onFile={(file) => setFileExcel(file)}
          file={fileExcel}
          text="Buscar archivo"
          sxc={{ marginTop: '5px' }}
          size="40%"
          name={'excel'}
          onDelete={() => setFileExcel(null)}
          accept=".xlsx"
        />
      </DialogContent>
      <DialogActions>
        <BotonNuevoComponent
          click={async () => {
            if (fileExcel === null || fileExcel === undefined) {
              return;
            }
            setLoadingExcel(true);
            await bulkExcelEventosAction(
              { dispatch, addNotification },
              {
                entityId: entidadesStore.activo.id,
                file: fileExcel,
              }
            );
            setLoadingExcel(false);
            setModalExcel(false);
            setFileExcel(null);
            getAllEventosAction(dispatch, {
              entityId: entidadesStore.activo.id,
              ...eventosStore.params,
            });
          }}
          text="SUBIR ARCHIVO"
          morado
        />
        <Button
          sx={{ color: MORADO_COLOR }}
          onClick={() => {
            setLoadingExcel(false);
            setModalExcel(false);
            setFileExcel(null);
          }}
        >
          CANCELAR
        </Button>
      </DialogActions>
    </>
  );
};

export default ModalExcelEventos;
