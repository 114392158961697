import { Autocomplete, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import * as Redux from 'react-redux';
// import { saveCodigosAction } from '../CodigosAction';
import {
  DatePicker,
  LocalizationProvider,
  PickersDay,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import * as yup from 'yup';
import LoadingComponent from '../../../components/Loading';
import { SU } from '../../../constants/ConfigConst';
import { useNotification } from '../../../helpers/notification';
import { getAllAreasService } from '../../areas/AreasService';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { getAllSubAreasService } from '../../subareas/SubAreasService';
import { getAllUsuariosCodigosService } from '../../userCodes/UserCodesService';
import { saveReservasService } from '../ReservasService';
import ReservasHours from './ReservaHours';
import FormUser from '../../usuarios/components/FormUser';
import AddIcon  from '@mui/icons-material/Add';
import { Modal } from '../../../components';
import { getDateTimezone } from '../../../helpers';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import RemoveIcon from '@mui/icons-material/Delete';

const FormReserva = ({
  setOpenModalForm, 
  params, 
  onRefresh,
  onChangeAreas,
  onChangeDates,
}) => {
  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);
  const [userCodes, setUserCodes] = React.useState([]);
  const [areas, setAreas] = React.useState([]);
  const [subareas, setSubareas] = React.useState([]);
  const [currentReservations, setCurrentReservations] = React.useState([]);
  const loginStore = Redux.useSelector((state) => state.login);
  const [modalFormUser, setModalFormUser] = React.useState(false);
  const [userCodeValue, setUserCodeValue] = React.useState(null);
  const [participantValue, setParticipantValue] = React.useState(null);
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const [isReset, setIsReset] = React.useState(false);
  const [isResetSubareas, setIsResetSubareas] = React.useState(false);
  const router = useHistory();

  const timezone = entidadesStore.activo.country.timezone;
  const utcOffset = entidadesStore.activo.country.utcOffset || 'UTC-04:00';

  const onSubmit = async (values, { resetForm, setFieldValue }) => {
    const authUserCode = loginStore.user.userCodes.find((el) => {
      return (
        (el.code.codeType === 'admin' &&
          entidadesStore.activo.id === el.code.entity.id) ||
        loginStore.user.roles.some((r) => r.name === SU)
      );
    });
    const dto = {
      subareaId: values.subareaId,
      userCodeId: values.userCodeId,
      reservations: values.reservations.map(({price, ...el}) => el),
      requestOrigin: 'web',
      createdById: authUserCode?.id,
      extras: values.extras,
      participantsCount: values.participantsCount,
      participantIds: values.participantIds?.map((el) => el?.id) || []
    };

    setLoading(true);
    try {
      const { data } = await saveReservasService(dto);
      onRefresh();
      setUserCodeValue(null);
      resetForm();
      setFieldValue('date', values.date);
      setOpenModalForm(false);
      setIsReset(true);
      setTimeout(() => {
        setIsReset(false);
      }, 10);
      
      addNotification('Reserva creada correctamente');
      router.push(`/reservaciones?areaId=${values.areaId}`);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response.data.message);
        addNotification(error.response.data.message, { error: true });
      }
    } finally {
      setLoading(false);
    }
  };

  const validationSchema = yup.object().shape({
    userCodeId: yup.number().integer().required('El usuario es requerido'),
    areaId: yup.number().integer().required('El área es requerida'),
    subareaId: yup.number().integer().required('La subárea es requerida'),
    date: yup
      .date()
      .required('La fecha es requerida')
      .min(
        new Date(dayjs().utcOffset(utcOffset).startOf('day').format()),
        'La fecha no puede ser menor a hoy'
      ),
    reservations: yup
      .array()
      .of(
        yup.object().shape({
          departureTime: yup.date().required(),
          entryTime: yup.date().required(),
        })
      )
      .required('Las reservas son requeridas')
      .min(1, 'Debe seleccionar al menos un horario')
      .max(4, 'No puede seleccionar más de 4 horarios')
      .test(
        'validate-continuous-reservations',
        'Los horarios deben ser continuos',
        (value) => {
          if (!value) return false;
          const sortedReservations = value.sort(
            (a, b) => a.entryTime - b.entryTime
          );
          console.log(sortedReservations);
          for (let i = 0; i < sortedReservations.length - 1; i++) {
            if (
              sortedReservations[i].departureTime.toISOString() !==
              sortedReservations[i + 1].entryTime.toISOString()
            ) {
              return false;
            }
          }
          return true;
        }
      ),
      extras: yup
        .array()
        .of(yup.object().shape({
          id: yup.number().integer().required(),
          quantity: yup.number().integer().required(),
          options: yup.array().of(yup.object().shape({
            optionId: yup.number().integer().required(),
            valueId: yup.number().integer().optional(),
            value: yup.string().optional().nullable(),
          })).optional(),
        }))
        .optional(),
      participantsCount: yup.number().integer().optional().nullable(),
      participantIds: yup.array().optional(),
  });
  

  React.useEffect(() => {
    onChangeDates([new Date(dayjs().utcOffset(utcOffset).startOf('day').format())]);
  }, []);

  const initialValue = {
    extras: [],
    extrasForm: [],
    reservations: [],
    areaId: null,
    subareaId: null,
    userCodeId: null,
    date: new Date(dayjs().utcOffset(utcOffset).startOf('day').format()),
    participantsCount: null,
    participantIds: [],
  };

  const getUserCodes = async (params = {}) => {
    const res = await getAllUsuariosCodigosService({
      entityId: entidadesStore.activo.id,
    });
    const data = res.data.data;
    const userCodes = data.map((el) => ({
      id: el.id,
      name: `${el.user.profile.firstName} ${el.user.profile.lastName}`,
      avatar: el.avatar || el.user.profile.avatar || null,
      email: el.email,
      documentId: `${el.user.profile.typeDocument}-${el.user.profile.identificationNumber}`,
      label: `(${el.code.code}) - ${el.user.profile.firstName} ${el.user.profile.lastName}`,
    }));
    if (res) setUserCodes(userCodes);
  };

  // const getAreas = async (params = {}) => {
  const getSubareas = async (params = {}) => {
    const res = await getAllSubAreasService({
      entityId: entidadesStore.activo.id,
    });
    const data = res.data.data;
    const subareas = data.map((el) => ({
      id: el.id,
      name: el.name,
      imageUrl: el.imageUrl,
      label: `${el.name}${el.price ? ' ($)' : ''}`,
      dates: el.dates,
      openingTime: el.openingTime,
      closingTime: el.closingTime,
      interval: el.interval,
      areaId: el.areaId,
      price: el.price,
      minPersons: el.minPersons,
      maxPersons: el.maxPersons,
      participantType: el.participantType,
    }));
    if (res) setSubareas(subareas);
  };

  const getAreas = async (params = {}) => {
    const res = await getAllAreasService({
      entityId: entidadesStore.activo.id,
    });
    const data = res.data.data;
    const areas = data.map((el) => ({
      id: el.id,
      name: el.name,
      imageUrl: el.imageUrl,
      label: el.name,
      openingTime: el.openingTime,
      closingTime: el.closingTime,
      blockReservation: el.blockReservation,
      extras: el.extras,
    }));
    if (res) setAreas(areas);
  };
  const findUserCode = (id) => userCodes.find((el) => el.id === id);
  const findArea = (id) => areas.find((el) => el.id === id);
  const findSubarea = (id) => subareas.find((el) => el.id === id);

  React.useEffect(() => {
    getUserCodes();
    getSubareas();
    getAreas();
  }, []);
  return (
    <Box component="form" sx={{ p: 1 }}>
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={true}
        >
        {({ values, setFieldValue, handleSubmit, errors, validateField, resetForm }) => {
          const subarea = findSubarea(values.subareaId);
          const area = findArea(values.areaId);
          if (subarea && subarea.minPersons > 1 && !values.participantsCount) {
            setFieldValue('participantsCount', subarea.minPersons - 1);
          }
          const totalExtras = values.extras.reduce((acc, el) => {
            const extra = area?.extras?.find((e) => e.id === el.id);
            return acc + (extra?.price || 0) * el.quantity;
          }, 0);
          const total = values.reservations.reduce((acc, el) => acc + el.price, 0) + totalExtras;
          return (
            <>
              {
                isReset ? null : (
                <Grid container spacing={2}>
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre>
                  <pre>{JSON.stringify(isReset, null, 2)}</pre> */}
                  <Grid item xs={12} md={12}>
                    <LoadingComponent isLoading={loading} text={'Guardando...'} />
                  </Grid>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', pl: 2 }}>
                    Nueva reserva
                  </Typography>
                  <Grid item xs={12} md={12}>
                    <FormControl fullWidth sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                      <Autocomplete
                        fullWidth
                        disableClearable
                        id="combo-box-usuario"
                        options={userCodes}
                        size="small"
                        value={userCodeValue}
                        style={{ flexGrow: 1 }}
                        renderInput={(params) => {
                          return (
                            <Grid>
                              <TextField
                                {...params}
                                label="Usuario"
                                placeholder="Buscar usuario"
                                onBlur={() => {
                                  validateField('userCodeId');
                                }}
                                helperText={errors.userCodeId || null}
                                error={!!errors.userCodeId}
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: values.userCodeId ? (
                                    <img
                                      style={{
                                        width: 30,
                                        height: 30,
                                        marginRight: 10,
                                        borderRadius: '50%',
                                        objectFit: 'cover',
                                        display: 'block',
                                      }}
                                      src={findUserCode(values.userCodeId)?.avatar}
                                      alt={findUserCode(values.userCodeId)?.label}
                                    />
                                  ) : null,
                                }}
                              />
                            </Grid>
                          );
                        }}
                        renderOption={(props, option, { selected }) => {
                          const { key, ...optionProps } = props;
                          return (
                            <li key={key} {...optionProps}>
                              <img
                                style={{
                                  width: 50,
                                  height: 50,
                                  marginRight: 10,
                                  borderRadius: '50%',
                                  objectFit: 'cover',
                                  display: 'block',
                                }}
                                src={option.avatar}
                                alt={option.label}
                              />
                              {option.label}
                            </li>
                          );
                        }}
                        key={(option) => option.id}
                        onChange={(e, value) => {
                          setUserCodeValue(value);
                          setFieldValue('userCodeId', value?.id || null);
                          setTimeout(() => {
                            validateField('userCodeId');
                          });
                        }}
                      />
                      {
                        entidadesStore.activo.isPublic && (
                          <Button 
                            variant="contained" 
                            color="primary" 
                            size="small" 
                            style={{ width: 40, height: 40, marginLeft: 10 }}
                            onClick={() => setModalFormUser(true)}>
                            <AddIcon />
                          </Button>
                        )
                      }
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl fullWidth>
                      <Autocomplete
                        fullWidth
                        disableClearable
                        id="combo-box-demo"
                        options={areas}
                        size="small"
                        renderInput={(params) => {
                          return (
                            <Grid>
                              <TextField
                                {...params}
                                label="Área"
                                placeholder="Buscar área"
                                onBlur={() => {
                                  validateField('areaId');
                                }}
                                helperText={errors.areaId || null}
                                error={!!errors.areaId}
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: values.areaId ? (
                                    <img
                                      style={{
                                        width: 30,
                                        height: 30,
                                        marginRight: 10,
                                        borderRadius: '50%',
                                        objectFit: 'cover',
                                        display: 'block',
                                      }}
                                      src={findArea(values.areaId)?.imageUrl}
                                      alt={findArea(values.areaId)?.label}
                                    />
                                  ) : null,
                                }}
                              />
                            </Grid>
                          );
                        }}
                        renderOption={(props, option, { selected }) => {
                          const { key, ...optionProps } = props;
                          return (
                            <li key={key} {...optionProps}>
                              <img
                                style={{
                                  width: 50,
                                  height: 50,
                                  marginRight: 10,
                                  borderRadius: '50%',
                                  objectFit: 'cover',
                                  display: 'block',
                                }}
                                src={option.imageUrl}
                                alt={option.label}
                              />
                              {option.label}
                            </li>
                          );
                        }}
                        key={(option) => option.id}
                        onChange={(e, value) => {
                          const findSubarea = subareas.find(
                            (el) => el.areaId === value?.id
                          );
                          setFieldValue('subareaId', null);
                          setIsResetSubareas(true);
                          setTimeout(() => {
                            setIsResetSubareas(false);
                          }, 10);
                          setFieldValue('areaId', value?.id || null);
                          if (value?.id) {
                            onChangeAreas([value.id]);
                          }
                          setTimeout(() => {
                            validateField('areaId');
                          });
                        }}
                      />
                    </FormControl>
                  </Grid>
                  {
                    isResetSubareas ? null : (
                      <Grid item xs={12} md={12}>
                        <FormControl fullWidth>
                          <Autocomplete
                            fullWidth
                            disableClearable
                            id="combo-box-subareas"
                            size="small"
                            options={subareas
                              .filter((el) => el.areaId === values.areaId)
                              .map((el) => el.id)}
                            getOptionLabel={(option) => findSubarea(option)?.label}
                            renderInput={(params) => {
                              return (
                                <Grid>
                                  <TextField
                                    {...params}
                                    label="Subárea"
                                    placeholder="Buscar subárea"
                                    onBlur={() => {
                                      validateField('subareaId');
                                    }}
                                    helperText={errors.subareaId || null}
                                    error={!!errors.subareaId}
                                    InputProps={{
                                      ...params.InputProps,
                                      startAdornment: values.subareaId ? (
                                        <img
                                          style={{
                                            width: 30,
                                            height: 30,
                                            marginRight: 10,
                                            borderRadius: '50%',
                                            objectFit: 'cover',
                                            display: 'block',
                                          }}
                                          src={findSubarea(values.subareaId)?.imageUrl}
                                          alt={findSubarea(values.subareaId)?.label}
                                        />
                                      ) : null,
                                    }}
                                  />
                                </Grid>
                              );
                            }}
                            renderOption={(props, _option, { selected }) => {
                              const option = findSubarea(_option);
                              const { key, ...optionProps } = props;
                              return (
                                <li key={key} {...optionProps}>
                                  <img
                                    style={{
                                      width: 50,
                                      height: 50,
                                      marginRight: 10,
                                      borderRadius: '50%',
                                      objectFit: 'cover',
                                      display: 'block',
                                    }}
                                    src={option.imageUrl}
                                    alt={option.label}
                                  />
                                  {option.label}
                                </li>
                              );
                            }}
                            key={(option) => option.id}
                            onChange={(e, value) => {
                              setFieldValue('subareaId', value || null);
                              setTimeout(() => {
                                validateField('subareaId');
                              });
                            }}
                          />
                        </FormControl>
                      </Grid>
                    )
                  }
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth>
                    <LocalizationProvider
                      adapterLocale={'es'}
                      dateAdapter={AdapterDayjs}
                    >
                      <DatePicker
                        label={'Fecha'}
                        value={values.date || null}
                        openTo="day"
                        size="small"
                        adapterLocale={'ve'}
                        shouldDisableDate={(date) => {
                          const nowTimezone = dayjs().utcOffset(utcOffset).startOf('day').format();
                          const dateTimezone = dayjs(date).utcOffset(utcOffset).set('days', date.get('days')).startOf('day').format();
                          if (new Date(nowTimezone) > new Date(dateTimezone)) {
                            return true;
                          }
  
                          if (findArea(values.areaId)?.blockReservation === 'day') {
                            if (
                              currentReservations.some((el) =>
                                dayjs(el.entryTime).isSame(date, 'day')
                              )
                            ) {
                              return true;
                            }
                          }
                          return false;
                        }}
                        renderDay={(day, _value, DayComponentProps) => {
                          const isReserved =
                            currentReservations.some((el) =>
                              dayjs(el.entryTime).isSame(day, 'day')
                            ) &&
                            findArea(values.areaId)?.blockReservation === 'day';
                          return (
                            <div style={{ position: 'relative' }}>
                              {isReserved && (
                                <span
                                  style={{
                                    position: 'absolute',
                                    top: '3px',
                                    right: '18px',
                                    width: '5px',
                                    height: '5px',
                                    display: 'block',
                                    backgroundColor: '#000',
                                    borderRadius: '100%',
                                    zIndex: '10',
                                  }}
                                ></span>
                              )}
                              <PickersDay {...DayComponentProps} />
                            </div>
                          );
                        }}
                        onChange={(date) => {
                          setFieldValue('date', date.toDate());
                          onChangeDates([date.toDate()]);
                          setTimeout(() => {
                            validateField('date');
                          });
                        }}
                        views={['day']}
                        minDate={dayjs().subtract(10, 'day').toDate()}
                        // InputProps={{
                        //   style: {
                        //     height: 56,
                        //   },
                        // }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            size="small"
                            required
                            onBlur={() => {
                              validateField('date');
                            }}
                            {...params}
                            helperText={errors.date || null}
                            error={!!errors.date}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                  <ReservasHours
                    area={findArea(values.areaId)}
                    subarea={findSubarea(values.subareaId)}
                    onChange={(reservations) => {
                      setFieldValue('reservations', reservations);
                    }}
                    onChangeCurrenReservations={(reservations) => {
                      setCurrentReservations(reservations);
                    }}
                  />
                  {
                    values.userCodeId && findArea(values.areaId)?.extras?.length > 0 && (
                      <Grid item xs={12} md={12}>
                        <FormControl fullWidth size="small" margin="normal">
                          <InputLabel id="extras">Agregar extras</InputLabel>
                          <Select
                            value={values.extrasForm}
                            label="Agregar extras"
                            size="small"
                            multiple
                            onChange={(e) => {
                              if (e.target.value.length < values.extrasForm.length) {
                                return;
                              }
                              const extraAdd = e.target.value.find((el) => !values.extrasForm.includes(el));
                              const areaExtra = findArea(values.areaId)?.extras.find((el) => el.id === extraAdd);
                              const extras = [...values.extras];
                              extras.push({
                                id: extraAdd,
                                quantity: 1,
                                options: areaExtra.options.map((el) => ({
                                  optionId: el.id,
                                  valueId: el.values[0]?.id || null,
                                  value: null
                                }))
                              });
                              setFieldValue('extras', extras)
                              setFieldValue('extrasForm', e.target.value);
                            }}
                          >
                            {
                              findArea(values.areaId)?.extras.map((extra) => (
                                <MenuItem key={extra.id} value={extra.id}>{extra.name}</MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>
                        {
                          values.extras.map((e, i) => {
                            const extra = findArea(values.areaId)?.extras.find((el) => el.id === e.id);
                            return (
                              <div key={e.id}>
                                <FormControl fullWidth size="small" margin="normal" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                  <Typography id={`extra-${e.id}`}>{extra.name}</Typography>
                                  <div>
                                  <TextField
                                    size="small"
                                    type="number"
                                    value={e.quantity}
                                    sx={{ width: 100 }}
                                    onChange={(e) => {
                                      const extras = [...values.extras];
                                      if (e.target.value > extras[i].quantity) {
                                        extras[i].options.push(...extra.options.map((el) => ({
                                          optionId: el.id,
                                          valueId: el.values[0]?.id || null,
                                          value: null
                                        })))
                                      } else if (e.target.value < extras[i].quantity) {
                                        for (const option of extra.options) {
                                          extras[i].options.pop()
                                        }
                                      }
                                      extras[i].quantity = Number(e.target.value);
                                      setFieldValue('extras', extras);
                                    }}
                                    inputProps={{
                                      min: 1
                                    }}
                                  />
                                  <Button variant="ghost" size="small" onClick={() => {
                                    const extras = [...values.extras];
                                    const extraForm = [...values.extrasForm];
                                    extras.splice(extras.indexOf(e), 1);
                                    extraForm.splice(extraForm.indexOf(e.id), 1);
                                    setFieldValue('extras', extras);
                                    setFieldValue('extrasForm', extraForm);
                                  }}>
                                    <RemoveIcon />
                                    </Button>
                                  </div>
                                </FormControl>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                                  {
                                    e.options.map((o, index) => {
                                      const option = extra.options.find((el) => el.id === o.optionId);
                                      return (
                                        <div key={o.optionId}>
                                          <Typography>{option.name} {index + 1}</Typography>
                                          {
                                            option.values?.length > 0 ? (
                                              <Select
                                                value={o.valueId}
                                                onChange={(e) => {
                                                  const extras = [...values.extras];
                                                  extras[i].options[index].valueId = e.target.value;
                                                  setFieldValue('extras', extras);
                                                }}
                                              >
                                                {option.values.map((v) => <MenuItem key={v.id} value={v.id}>{v.value}</MenuItem>)}
                                              </Select>
                                            ) : null
                                          }
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              </div>
                            )
                          })
                        }
                      </Grid>
                    )
                  }
                  {
                    values.userCodeId && subarea?.maxPersons > 1 && subarea?.participantType === 'total_only' && (
                      <Grid item xs={12} md={12}>
                        <FormControl fullWidth size="small" margin="normal">
                          <TextField
                            size="small"
                            type="number"
                            label="Cantidad de acompañantes"
                            value={values.participantsCount}
                            onChange={(e) => {
                              setFieldValue('participantsCount', Number(e.target.value));
                            }}
                            inputProps={{
                              min: subarea?.minPersons - 1,
                              max: subarea?.maxPersons - 1
                            }}
                          />
                        </FormControl>
                      </Grid>
                    )
                  }

                  {
                    values.userCodeId && subarea?.maxPersons > 1 && subarea?.participantType === 'detailed' && (
                      <Grid item xs={12} md={12}>
                        <FormControl fullWidth sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                          <Autocomplete
                            fullWidth
                            disableClearable
                            id="combo-box-usuario"
                            options={userCodes.filter((el) => el.id !== values.userCodeId)}
                            multiple
                            size="small"
                            value={values.participantIds}
                            style={{ flexGrow: 1 }}
                            renderInput={(params) => {
                              return (
                                <Grid>
                                  <TextField
                                    {...params}
                                    label="Acompañantes"
                                    placeholder="Buscar acompañante"
                                    // onBlur={() => {
                                    //   validateField('userCodeId');
                                    // }}
                                    // helperText={errors.userCodeId || null}
                                    // error={!!errors.userCodeId}
                                  />
                                </Grid>
                              );
                            }}
                            renderOption={(props, option, { selected }) => {
                              const { key, ...optionProps } = props;
                              return (
                                <li key={key} {...optionProps}>
                                  <img
                                    style={{
                                      width: 50,
                                      height: 50,
                                      marginRight: 10,
                                      borderRadius: '50%',
                                      objectFit: 'cover',
                                      display: 'block',
                                    }}
                                    src={option.avatar}
                                    alt={option.label}
                                  />
                                  {option.label}
                                </li>
                              );
                            }}
                            key={(option) => option.id}
                            onChange={(e, value) => {
                              if (value.length > subarea?.maxPersons - 1) {
                                return;
                              }
                              setFieldValue('participantIds', value);
                              setTimeout(() => {
                                validateField('participantIds');
                              });
                            }}
                          />
                          {
                            entidadesStore.activo.isPublic && (
                              <Button 
                                variant="contained" 
                                color="primary" 
                                size="small" 
                                style={{ width: 40, height: 40, marginLeft: 10 }}
                                onClick={() => setModalFormUser(true)}>
                                <AddIcon />
                              </Button>
                            )
                          }
                        </FormControl>
                      </Grid>
                    )
                  }
                  <Grid item md={12} align="center" sx={{ m: 2 }}>
                    {!!total && (
                      <Box sx={{ marginBottom: 1 }}>
                        Total: $
                        {total}
                      </Box>
                    )}
                    <BotonNuevoComponent
                      click={handleSubmit}
                      disabled={loading}
                      text="GUARDAR"
                      morado
                    />
                  </Grid>
                  <Modal
                    id="modalFormReserva"
                    title={'Nueva Usuario'}
                    open={modalFormUser}
                    maxWidth="sm"
                    onClose={() => setModalFormUser(!modalFormUser)}
                  >
                    <FormUser
                      setOpenModalForm={(val) => setModalFormUser(val)}
                      onSuccess={(user) => {
                        const userCode = user.userCodes.find((uc) => uc.code.entity.id === entidadesStore.activo.id);
                        const userCodeData = {
                          id: userCode.id,
                          name: `${user.profile.firstName} ${user.profile.lastName}`,
                          avatar: userCode.avatar || user.profile.avatar || null,
                          email: user.email,
                          documentId: `${user.profile.typeDocument}-${user.profile.identificationNumber}`,
                          label: `(${userCode.code.code}) - ${user.profile.firstName} ${user.profile.lastName}`,
                        };
                        setUserCodes([...userCodes, userCodeData]);
                        console.log(userCode, user)
                        setFieldValue('userCodeId', userCode.id);
                        setUserCodeValue(userCodeData);
                        setTimeout(() => {
                          validateField('userCodeId');
                        });
                        setModalFormUser(false);
                        addNotification('Usuario asignado correctamente', { success: true });
                      }}
                      onClose={() => setModalFormUser(false)}
                    />
                  </Modal>
                </Grid>
                )
              }
            
            </>
          )
        }}
      </Formik>
    </Box>
  );
};

export default FormReserva;
