import { Autocomplete, Checkbox, FormControl, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import * as React from 'react';
import * as Redux from 'react-redux';
import LoadingComponent from '../../../components/Loading';
import { useNotification } from '../../../helpers/notification';
import { saveEntidadesAction } from '../EntidadesAction';
import BotonNuevoComponent from './BotonNuevo';

const FormEntityRoot = ({ openModal = () => {} }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);

  const [loadingSave, setLoadingSave] = React.useState(false);
  const [data, setData] = React.useState({
    isMinorRegistrationAllowed: entidadesStore.activo?.isMinorRegistrationAllowed || false,
    isPaymentConfirmation: entidadesStore.activo?.isPaymentConfirmation || true,
  });

  const _handleSubmit = async () => {
    setLoadingSave(true);
    let res = await saveEntidadesAction(
      { dispatch, addNotification },
      data,
      {
        id: entidadesStore.activo.id,
        isSU: true,
      },
      entidadesStore.params
    );
    if (openModal) {
      openModal(!res);
    }
    setLoadingSave(false);
  };

  return (
    <Box component="form">
      <Grid container spacing={2} sx={{ mt: loadingSave ? 2 : 0 }}>
        <LoadingComponent
          isLoading={loadingSave || loadingSave}
          text="Guardando..."
        />
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.isMinorRegistrationAllowed}
                  value={data.isMinorRegistrationAllowed}
                  onChange={(e) =>
                    setData({
                      ...data,
                      isMinorRegistrationAllowed: e.target.checked,
                    })
                  }
                  name={data.isMinorRegistrationAllowed}
                />
              }
              label="Permitir registro de menores"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  value={data.isPaymentConfirmation}
                  checked={data.isPaymentConfirmation}
                  onChange={(e) => {
                    const newEntidad = {
                      ...data,
                      isPaymentConfirmation: e.target.checked,
                    };
                    setData(newEntidad);
                  }}
                  name='isPaymentConfirmation'
                />
              }
              label="Confirmación de pago"
            />
          </FormControl>
        </Grid>
        <Grid item md={12} align="center" sx={{ p: 2 }}>
          {entidadesStore.activo && (
            <BotonNuevoComponent
              click={_handleSubmit}
              disabled={loadingSave}
              text="Guardar"
              morado
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

FormEntityRoot.propTypes = {
  openModal: PropTypes.func,
};

export default FormEntityRoot;
