import { Grid, Paper } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import BotonNuevoComponent from '../entidades/components/BotonNuevo';
import { checkModuleAccess } from '../modulos/modules.helpers';
import { getAllApiKeysAction, setActivoApiKeysAction } from './ApiKeysAction';
import FormApiKeys from './components/FormApiKeys';
import ListadoApiKeys from './components/ListadoApiKeys';

const ApiKeysView = () => {
  const dispatch = Redux.useDispatch();
  const apiKeysStore = Redux.useSelector((state) => state.apiKeys);
  const loginStore = Redux.useSelector((state) => state.login);

  const accessName = 'banks';
  if (!checkModuleAccess(accessName, loginStore)) {
    window.location.href = '/dashboard';
  }

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <BotonNuevoComponent
          click={async () => {
            await setActivoApiKeysAction(dispatch, null);
          }}
          azul
          mr={2}
        />
        {apiKeysStore.all.length > 0 && (
          <BotonNuevoComponent
            click={async () => {
              await setActivoApiKeysAction(dispatch, null);
              await getAllApiKeysAction(dispatch, apiKeysStore.params);
            }}
            white
            disabled={apiKeysStore.loadingGetAll}
            text={apiKeysStore.loadingGetAll ? 'Actualizando...' : 'Actualizar'}
          />
        )}
      </Grid>
      <Grid item md={4}>
        <Paper elevation={4}>
          <FormApiKeys />
        </Paper>
      </Grid>
      <Grid item md={8}>
        <ListadoApiKeys />
      </Grid>
    </Grid>
  );
};

export default ApiKeysView;

export const mesesArray = [
  { nombre: 'Enero', valor: '01' },
  { nombre: 'Febrero', valor: '02' },
  { nombre: 'Marzo', valor: '03' },
  { nombre: 'Abril', valor: '04' },
  { nombre: 'Mayo', valor: '05' },
  { nombre: 'Junio', valor: '06' },
  { nombre: 'Julio', valor: '07' },
  { nombre: 'Agosto', valor: '08' },
  { nombre: 'Septiembre', valor: '09' },
  { nombre: 'Octubre', valor: '10' },
  { nombre: 'Noviembre', valor: '11' },
  { nombre: 'Diciembre', valor: '12' },
];
