import React from 'react';
import ContainerTemplate from '../../components/template/Container';
import * as ROUTES from '../../router/Routes';
import Morosidadview from './MorosidadView';

const MorosidadModule = () => {
  return (
    <ContainerTemplate active={ROUTES.morosidad} routes={[]}>
      <Morosidadview />
    </ContainerTemplate>
  );
};

export default MorosidadModule;
