import React from 'react';
import ContainerTemplate from '../../components/template/Container';
import * as ROUTES from '../../router/Routes';
import ApiKeysview from './ApiKeysView';

const ApiKeysModule = () => {
  return (
    <ContainerTemplate active={ROUTES.apikeys} routes={[]}>
      <ApiKeysview />
    </ContainerTemplate>
  );
};

export default ApiKeysModule;
