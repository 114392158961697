import { tryCatchHelper } from '../../helpers';
import {
  GETALL_APIKEYS,
  LOADING_GETALL_APIKEYS,
  SET_ACTIVO_APIKEYS,
} from '../../store/Types';
import { deleteApiKeysService, getAllApiKeysService, saveApiKeysService, updateTokenApiKeysService } from './ApiKeysService';

export const getAllApiKeysAction = async (dispatch, params) => {
  try {
    dispatch({
      type: LOADING_GETALL_APIKEYS,
      payload: true,
    });
    const res = await getAllApiKeysService(params);
    dispatch({
      type: GETALL_APIKEYS,
      payload: res.data,
    });
    dispatch({
      type: LOADING_GETALL_APIKEYS,
      payload: false,
    });
    return res.data;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_APIKEYS,
      payload: false,
    });
    tryCatchHelper(e);
    return false;
  }
};

export const saveApiKeysAction = async (
  { dispatch, addNotification },
  id,
  data,
  params
) => {
  try {
    let res = await saveApiKeysService(id, data, params);
    addNotification(
      res.data.message ?? id ? 'Banco actualizado.' : 'Banco registrado.'
    );
    await getAllApiKeysAction(dispatch, params);
    setActivoApiKeysAction(dispatch, null);
    return res;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoApiKeysAction = async (dispatch, data) => {
  dispatch({
    type: SET_ACTIVO_APIKEYS,
    payload: data,
  });
};

export const deleteApiKeysAction = async (
  { dispatch, addNotification },
  id,
  params
) => {
  try {
    const res = await deleteApiKeysService(id);
    if (res.status === 200) {
      addNotification(res.data.message ?? 'API KEY eliminada.');
      await setActivoApiKeysAction(dispatch, null);
      await getAllApiKeysAction(dispatch, params);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const updateTokenApiKeysAction = async (
  { dispatch, addNotification },
  id,
  data,
  params
) => {
  try {
    const res = await updateTokenApiKeysService(id, data);
    if (res.status === 200) {
      addNotification(res.data.message ?? 'API KEY actualizada.');
      await getAllApiKeysAction(dispatch, params);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

