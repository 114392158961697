import {
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import React from 'react';
import * as CONST from '../../../../constants/FieldsConst';
import { dateformat } from '../../../../helpers';

const FechasTablaEventos = ({ dates, comparaHoras, setFieldValue }) => {
  return (
    <TableContainer>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '43%' }} align="left">
              Fecha
            </TableCell>
            <TableCell align="center">Hora inicio</TableCell>
            <TableCell align="center">Hora final</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dates.map((item, index) => {
            return (
              <TableRow
                key={`row_${index}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">
                  {dateformat(item.date, { format: 'DD/MM/YYYY' })}
                </TableCell>
                <TableCell align="left">
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      size="small"
                      type="time"
                      value={item.startTime}
                      onChange={(e) => {
                        const res = comparaHoras(
                          true,
                          e.target.value,
                          dates[index].endTime
                        );
                        if (!res) {
                          const datesValues = [...dates];
                          datesValues[index].startTime = `${e.target.value}:00`;
                          setFieldValue('dates', datesValues);
                        }
                      }}
                      id={CONST.START_TIME}
                    />
                  </FormControl>
                </TableCell>
                <TableCell align="left">
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      size="small"
                      type="time"
                      value={item.endTime}
                      onChange={(e) => {
                        const res = comparaHoras(
                          false,
                          dates[index].startTime,
                          e.target.value
                        );
                        if (!res) {
                          const datesValues = [...dates];
                          datesValues[index].endTime = `${e.target.value}:00`;
                          setFieldValue('dates', datesValues);
                        }
                      }}
                      id={CONST.START_TIME}
                    />
                  </FormControl>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FechasTablaEventos;
