import { Autocomplete, Checkbox, FormControl, FormControlLabel, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import * as React from 'react';
import * as Redux from 'react-redux';
import LoadingComponent from '../../../components/Loading';
import { useNotification } from '../../../helpers/notification';
import { saveEntidadesAction, setActivoEntidadesAction } from '../EntidadesAction';
import BotonNuevoComponent from './BotonNuevo';
import { updateMorosityTimeEntidadesService } from '../EntidadesService';
import useFetch from 'http-react'
import { baseApi, token } from '../../../services/Apis';
import { getPluralLabelTypeEntity } from '../../../constants/enumsConst';

const FormEntityMorosity = ({ openModal = () => {} }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);

  const [loadingSave, setLoadingSave] = React.useState(false);
  const [data, setData] = React.useState({
    resources: entidadesStore.activo?.entityResources || [],
    codeLevels: entidadesStore.activo?.codeLevels || []
  });

  
  // const { data: codeLevels } = useFetch(`${baseApi}/code-levels`, {
  //   headers: token({}),
  //   query: {
  //     entityId: entidadesStore.activo.id
  //   }
  // })

  const _handleSubmit = async () => {
    setLoadingSave(true);
    const res = await updateMorosityTimeEntidadesService({
      entityId: entidadesStore.activo.id,
      resources: data.resources,
      codeLevels: data.codeLevels
    });
    const { entityResources, ...rest } = entidadesStore.activo;
    await setActivoEntidadesAction(dispatch, { ...rest, entityResources: res.data });
    setLoadingSave(false);
  };

  return (
    <Box component="form">
      <Grid container spacing={2} sx={{ mt: loadingSave ? 2 : 0 }}>
        <LoadingComponent
          isLoading={loadingSave || loadingSave}
          text="Guardando..."
        />

        <Grid item xs={12} md={12}>
          <h3>Niveles de morosidad</h3>
        </Grid>
        <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Nombre</TableCell>
                        <TableCell align="left">Tiempo</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data.codeLevels?.map((item, index) => {
                            return (
                                <TableRow
                                    key={`row_${index}`}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left">{item.name}</TableCell>
                                    <TableCell align="left">
                                      <TextField
                                        type="number"
                                        value={item.morosityTime}
                                        size="small"
                                        onChange={(e) => {
                                          const newCodeLevels = [...data.codeLevels];
                                          newCodeLevels[index].morosityTime = Number(e.target.value);
                                          setData({ ...data, codeLevels: newCodeLevels });
                                        }}
                                        inputProps={{
                                          min: entidadesStore.activo.morosityTime
                                        }}
                                      />
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
        
        <Grid item xs={12} md={12}>
          <h3>Recursos</h3>
        </Grid>
        {data.resources.map((resource, index) => (
          <Grid item xs={12} md={3}>
            <Box fullWidth style={{ display: 'flex', alignItems: 'center', gap: 2}}>
              <FormControlLabel
                label={resource.resource.code === 'codes' ? getPluralLabelTypeEntity(resource.resource.code) : resource.resource.name}
                control={
                  <Checkbox
                    checked={resource.isMorosityBlocked}
                    onChange={(e) => {
                      const newResources = [...data.resources];
                      newResources[index].isMorosityBlocked = e.target.checked;
                      setData({ ...data, resources: newResources });
                    }}
                  />
                }
              />
            </Box>
          </Grid>
        ))}
        <Grid item md={12} align="center" sx={{ p: 2 }}>
          {entidadesStore.activo && (
            <BotonNuevoComponent
              click={_handleSubmit}
              disabled={loadingSave}
              text="Guardar"
              morado
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

FormEntityMorosity.propTypes = {
  openModal: PropTypes.func,
};

export default FormEntityMorosity;
