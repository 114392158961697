import * as ROUTES from '../router/Routes';
import {
  AZUL_COLOR,
  CYAN_COLOR,
  ERROR_COLOR,
  MORADO_COLOR,
} from './ColorsConst';

export const OWNER = 'owner';
export const SECURITY = 'security';
export const ENTITY = 'entity';
export const FAMILY = 'family';
export const NANNY = 'nanny';
export const SERVICE = 'service';
export const DRIVER = 'driver';
export const CLIENT = 'client';
export const ADMIN = 'admin';
export const MANAGER = 'manager';
export const TEACHER = 'teacher';
export const OTHER = 'other';

export const DAY = 'day';
export const HOUR_BLOCK = 'hour_block';

export const STATUS_ENUM = {
  ACTIVE: 'ACTIVE',
  BLOCKED: 'BLOCKED',
  EMAIL_NOT_VERIFIED: 'EMAIL_NOT_VERIFIED',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  REJECTED: 'REJECTED',
  TO_CHECK: 'TO_CHECK',
};

export const statusEnum = {
  ACTIVE: 'Activo',
  BLOCKED: 'Bloqueado',
  EMAIL_NOT_VERIFIED: 'Correo no Verificado',
  PENDING_APPROVAL: 'Pendiente por aprobar',
  REJECTED: 'Rechazado',
  TO_CHECK: 'Por verificar',
  DEBT: 'Deudor',
  INCOMPLETE_REGISTRATION: 'Registro incompleto',
  PRE_CREATED: 'Registro incompleto',
};

export const statusColorEnum = {
  ACTIVE: CYAN_COLOR,
  BLOCKED: AZUL_COLOR,
  EMAIL_NOT_VERIFIED: AZUL_COLOR,
  PENDING_APPROVAL: MORADO_COLOR,
  TO_CHECK: MORADO_COLOR,
  REJECTED: ERROR_COLOR,
  DEBT: MORADO_COLOR,
  INCOMPLETE_REGISTRATION: MORADO_COLOR,
  PRE_CREATED: MORADO_COLOR,
};

export const getCodeType = (type, isClub) => {
  if (!isClub) {
    if (type === OWNER) return 'Propietario Admin';
    if (type === FAMILY) return 'Propietario';
    if (type === OTHER) return 'Familiar';
  }
  return CODE_TYPE_ENUM[type];
};

export const CODE_TYPE_ENUM = {
  [OWNER]: 'Propietario',
  [SECURITY]: 'Seguridad',
  [ENTITY]: 'Entidad',
  [FAMILY]: 'Familiar',
  [NANNY]: 'Niñera',
  [SERVICE]: 'Trabajador doméstico',
  [DRIVER]: 'Chofer',
  [CLIENT]: 'Cliente',
  [ADMIN]: 'Admin',
  [OTHER]: 'Otro',
  [MANAGER]: 'Manager',
  [TEACHER]: 'Profesor',
};

export const TYPE_ENUM = {
  [ADMIN]: 'Admin',
  [CLIENT]: 'Cliente',
};

export const TIPO_RESERVA_ENUM = {
  [HOUR_BLOCK]: 'Bloques',
  [DAY]: 'Todo el día',
};

export const GENDER_ENUM = {
  male: 'Masculino',
  female: 'Femenino',
  other: 'Otro',
  unspecified: 'Sin Específicar',
};

export const TIPOS_PAGOS_ENUM = {
  debt: 'DEUDA',
  pay: 'Pagado',
};

export const TIPOS_DOCUMENTOS_ENUM = {
  maintenance: 'Mantenimiento',
  administrative: 'Administrativo',
  rules: 'Reglamento',
  regulations: 'Normativa',
  communications: 'Comunicado',
};

export const DASHBOARD_ENUM = {
  users: ROUTES.usuarios,
  reservations: ROUTES.reservasEspeciales,
  guests: ROUTES.invitados,
  areas: ROUTES.areas,
  subareas: {
    title: ROUTES.subareas.title,
    icon: ROUTES.subareas.icon,
  },
  codeDebs: {
    title: 'Deudores',
    icon: ROUTES.usuarios.icon,
  },
  codeActives: {
    title: 'Código activos',
    icon: ROUTES.usuarios.icon,
  },
  codeBlocked: {
    title: 'Código bloqueados',
    icon: ROUTES.usuarios.icon,
  },
  // good_payers: {
  //   title: "Sin morosidad",
  //   icon: ROUTES.usuarios.icon,
  // },
};

export const MODULOS_ENUM = {
  Contacts: 'Contactos',
  Control: 'Accesos',
  Documents: 'Documentos',
  Emergencies: 'Emergencias',
  History: 'Historial',
  Invitations: 'Invitaciones',
  Payments: 'Recibos',
  Profiles: 'Perfil',
  Reserves: 'Reservas',
  Scanner: 'Escanner',
  Services: 'Servicios',
  Delivery: 'Delivery',
  About: 'About',
  Codes: 'Códigos',
  Users: 'Usuarios',
  Classes: 'Clases',
  Events: 'Eventos',
  Subareas: 'Subáreas',
  Areas: 'Áreas',
  AccessLogs: 'Apertura de puertas',
  Banks: 'Bancos',
  Restaurants: 'Restaurantes',
  Referrals: 'Referidos',
};

export const TYPE_ENTITY_ENUM = {
  home: 'Residencia',
  residence: 'Residencia',
  club: 'Club',
  office: 'Oficina',
  entertainmentCenter: 'Centro de entretenimiento',
};

export const getLabelTypeEntity = (type) => {
  if (type === 'home') return 'Residencia';
  if (type === 'residence') return 'Residencia';
  if (type === 'club') return 'Acción';
  if (type === 'office') return 'Código';
  if (type === 'entertainmentCenter') return 'Código';
  if (type === 'restaurant') return 'Código';
  return 'Residencia';
};

export const getPluralLabelTypeEntity = (type) => {
  if (type === 'home') return 'Residencias';
  if (type === 'residence') return 'Residencias';
  if (type === 'club') return 'Acciones';
  if (type === 'office') return 'Códigos';
  if (type === 'entertainmentCenter') return 'Códigos';
  if (type === 'restaurant') return 'Códigos';
  return 'Residencias';
};
