import axios from 'axios';
import { baseApi, token } from '../../../services/Apis';

export const countriesApi = `${baseApi}/countries`;

export const getAllCountriesService = async (params) => {
  const res = await axios.get(countriesApi, {
    headers: await token({}),
    params,
  });
  return res;
};

export const getOneCountriesService = async (id) => {
  const res = await axios.get(`${countriesApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteCountriesService = async (id) => {
  const res = await axios.delete(`${countriesApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};
