import axios from 'axios';
import { baseApi, token } from '../../services/Apis';

export const customPrices = `${baseApi}/custom-prices`;

export const getAllPreciosCustomService = async (params) => {
  const res = await axios.get(customPrices, {
    headers: await token({}),
    params,
  });
  return res;
};

export const savePreciosCustomService = async (data) => {
  let res = await axios.post(`${customPrices}/subareas`, data, {
    headers: await token({}),
  });
  return res;
};

export const getOnePreciosCustomService = async (id) => {
  const res = await axios.get(`${customPrices}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deletePreciosCustomService = async (id, params) => {
  const res = await axios.delete(`${customPrices}/${id}`, {
    params,
    headers: await token({}),
  });
  return res;
};
