import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, styled, TextField, Typography } from "@mui/material"
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useEffect, useState } from "react"
import dayjs from "dayjs"
import { capitalize, formatTime } from "../../../helpers"
import TimeInput from "../../../components/TimeInput"
import * as Redux from 'react-redux';
const StyledAccordion = styled(Accordion)(({ theme }) => ({
  '&.MuiAccordion-root': {
    border: 'none',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  },
}))

// Custom styled AccordionSummary
const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  '& .MuiAccordionSummary-content': {
    margin: theme.spacing(1, 0),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: theme.palette.primary.main,
  },
}))



const TimeBlockGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
}))

const TimeBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  transition: 'all 0.3s ease',
  '&:hover': {
    borderColor: theme.palette.primary.main,
    boxShadow: theme.shadows[2],
  },
}))

const initData = [
  {
    day: 1,
    blocks: [
      {
        start: '08:00:00',
        end: '20:00:00',
        price: 0,
        interval: 30,
        minutesUse: 30
      }
    ]
  },
  {
    day: 2,
    blocks: [
      {
        start: '08:00:00',
        end: '20:00:00',
        price: 0,
        interval: 30,
        minutesUse: 30
      }
    ]
  },
  {
    day: 3,
    blocks: [
      {
        start: '08:00:00',
        end: '20:00:00',
        price: 0,
        interval: 30,
        minutesUse: 30
      }
    ]
  },
  {
    day: 4,
    blocks: [
      {
        start: '08:00:00',
        end: '20:00:00',
        price: 0,
        interval: 30,
        minutesUse: 30
      }
    ]
  },
  {
    day: 5,
    blocks: [
      {
        start: '08:00:00',
        end: '20:00:00',
        price: 0,
        interval: 30,
        minutesUse: 30
      }
    ]
  },
  {
    day: 6,
    blocks: [
      {
        start: '08:00:00',
        end: '20:00:00',
        price: 0,
        interval: 30,
        minutesUse: 30
      }
    ]
  },
  {
    day: 0,
    blocks: [
      {
        start: '08:00:00',
        end: '20:00:00',
        price: 0,
        interval: 30,
        minutesUse: 30
      }
    ]
  }
]

export default function FormSubareaSchedule({ 
  data, 
  onChange = () => {}, 
  edit = false
}) {
  const [schedules, setSchedules] = useState(data || initData)
  const [openDialog, setOpenDialog] = useState(null)
  const areasStore = Redux.useSelector(state => state.areas)
  useEffect(() => {
    onChange(schedules)
  }, [schedules])

  const handleAddBlock = (day) => {
    const dayIndex = schedules.findIndex(s => s.day === day);
    setOpenDialog({
      day,
      block: null,
      prevBlock: schedules[dayIndex].blocks[schedules[dayIndex].blocks.length - 1],
    })
  }

  const handleEditBlock = (day, index) => {
    const schedule = schedules.find(s => s.day === day)
    setOpenDialog({
      day,
      block: schedule.blocks[index],
      nextBlock: schedule.blocks[index + 1],
      prevBlock: schedule.blocks[index - 1],
      index
    })
  }

  const handleDeleteBlock = (day, index) => {
    setSchedules(prev => {
      const newSchedules = [...prev]
      const dayIndex = newSchedules.findIndex(s => s.day === day)
      newSchedules[dayIndex].blocks.splice(index, 1)
      return newSchedules
    })
  }

  const handleSaveBlock = (block) => {
    if (openDialog.block) {
      setSchedules(prev => {
        const newSchedules = [...prev]
        const day = newSchedules.findIndex(s => s.day === openDialog.day)
        newSchedules[day].blocks[openDialog.index] = block
        return newSchedules
      })
    } else {
      setSchedules(prev => {
        const newSchedules = [...prev]
        const day = newSchedules.findIndex(s => s.day === openDialog.day)
        newSchedules[day].blocks.push(block)
        return newSchedules
      })
    }
    setOpenDialog(null)
  }


  return (
    <Paper
      elevation={1}
      sx={{ 
        maxWidth: 600, 
        mx: 'auto', 
        p: 2,
        border: '1px solid',
        borderColor: 'divider'
      }}
    >
      {schedules.map((schedule, index) => {
        const firstBlock = schedule.blocks[0]
        const lastBlock = schedule.blocks[schedule.blocks.length - 1]
        const isStart = firstBlock?.start === areasStore.activo.openingTime
        const isEnd = lastBlock?.end === areasStore.activo.closingTime
        return (
          <StyledAccordion key={index}>
            <StyledAccordionSummary>
              <Typography>{capitalize(dayjs().day(schedule.day).format('dddd'))}</Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
                {schedule.blocks.length > 0 ? `${formatTime(parseTime(schedule.blocks[0].start))} - ${formatTime(parseTime(schedule.blocks[schedule.blocks.length - 1].end))}` : 'Sin bloques'}
              </Typography>
            </StyledAccordionSummary>
            <AccordionDetails>
              <TimeBlockGrid>
                {schedule.blocks.map((block, bi) => (
                  <TimeBlock key={bi}>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      Bloque {bi + 1}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      {formatTime(parseTime(block.start))} - {formatTime(parseTime(block.end))}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Precio: {block.price}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Intervalo: {block.interval}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Minutos de uso: {block.minutesUse}
                    </Typography>
                    {edit && (
                      <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton size="small" onClick={() => handleEditBlock(schedule.day, bi)}>
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton size="small" onClick={() => handleDeleteBlock(schedule.day, bi)}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    )}
                  </TimeBlock>
                ))}
              </TimeBlockGrid>
              {!isEnd && edit && (
                <Button startIcon={<AddIcon />} onClick={() => handleAddBlock(schedule.day)}>
                  Agregar bloque
                </Button>
              )}
            </AccordionDetails>
          </StyledAccordion>
        )
      })}

      <BlockDialog
        open={!!openDialog}
        onClose={() => setOpenDialog(null)}
        onSave={handleSaveBlock}
        block={openDialog?.block}
        nextBlock={openDialog?.nextBlock}
        prevBlock={openDialog?.prevBlock}
      />
    </Paper>
  );
}


const parseTime = (timeStr) => {
  const [h, m, s] = timeStr.split(':').map(Number);
  const date = new Date();
  date.setHours(h);
  date.setMinutes(m);
  date.setSeconds(s);
  return date;
};

const addMinutes = (timeStr, minutes) => {
  const time = parseTime(timeStr);
  return dayjs(time).add(minutes, 'minute').format('HH:mm:ss')
}

const subMinutes = (timeStr, minutes) => {
  const time = parseTime(timeStr);
  return dayjs(time).subtract(minutes, 'minute').format('HH:mm:ss')
}

function BlockDialog({ open, onClose, onSave, block, nextBlock, prevBlock }) {
  const areasStore = Redux.useSelector(state => state.areas)
  const [formData, setFormData] = useState(block || {
    start: prevBlock?.end,
    end: nextBlock?.start,
    price: 0,
    interval: 30,
    minutesUse: 30
  })

  useEffect(() => {
    if (!block) {
      const interval = prevBlock?.interval || nextBlock?.interval || 30
      let start = prevBlock?.end || areasStore.activo.openingTime
      let end = nextBlock?.start || areasStore.activo.closingTime
      if (end === start && end !== '00:00:00') {
        end = addMinutes(start, interval)
      }
      setFormData({
        start,
        end,
        price: prevBlock?.price || nextBlock?.price || 0,
        interval,
        minutesUse: prevBlock?.minutesUse || nextBlock?.minutesUse || 30
      })
    } else {
      setFormData({
        start: block.start,
        end: block.end,
        price: block.price,
        interval: block.interval,
        minutesUse: block.minutesUse
      })
    }
  }, [block, nextBlock, prevBlock])

  useEffect(() => {
    if (formData.minutesUse < formData.interval) {
      setFormData(prev => ({ ...prev, minutesUse: formData.interval }))
    }
  }, [formData.interval])

  const handleChange = (e) => {
    setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onSave(formData)
  }

  // const endMaxTime =

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{block ? 'Editar bloque' : 'Agregar bloque'}</DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="text.secondary">
          Min: {prevBlock?.end || areasStore.activo.openingTime} - Max: {nextBlock?.start || areasStore.activo.closingTime}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            name="price"
            label="Precio"
            type="number"
            value={formData.price}
            onChange={handleChange}
            fullWidth
            margin="normal"
            size="small"
          />
          <TextField
            name="interval"
            label="Intervalo"
            type="number"
            value={formData.interval}
            onChange={(e) => {
              setFormData(prev => ({ ...prev, interval: Number(e.target.value) }))
            }}
            fullWidth
            margin="normal"
            size="small"
            InputProps={{
              inputProps: {
                min: 15
              }
            }}
          />
          <TextField
            name="minutesUse"
            label="Minutos de uso"
            type="number"
            value={formData.minutesUse}
            onChange={(e) => {
              setFormData(prev => ({ ...prev, minutesUse: Number(e.target.value) }))
            }}
            fullWidth
            margin="normal"
            size="small"
            InputProps={{
              inputProps: {
                min: 15
              }
            }}
          />
          <Box sx={{ display: 'flex', gap: 2 }}>
            <TimeInput
              value={formData.start}
              onChange={(value) => setFormData(prev => ({ ...prev, start: value }))}
              minTime={prevBlock?.end || areasStore.activo.openingTime}
              maxTime={subMinutes(nextBlock?.start || areasStore.activo.closingTime, formData.interval)}
              interval={formData.interval}
              use12Hours
              label="Hora de inicio"
            />
            <TimeInput
              value={formData.end}
              onChange={(value) => setFormData(prev => ({ ...prev, end: value }))}
              minTime={addMinutes(formData.start || areasStore.activo.openingTime, formData.interval)}
              maxTime={nextBlock?.start || areasStore.activo.closingTime}
              interval={formData.interval}
              use12Hours
              label="Hora de fin"
            />
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

