import { CLEAR_ALL, SET_LOGOUT } from '../../../store/Types';

export const GETALL_STATES = 'GETALL_STATES';
export const ACTIVO_STATES = 'ACTIVO_STATES';
export const LOADING_GETALL_STATES = 'LOADING_GETALL_STATES';
export const PARAMS_STATES = 'PARAMS_STATES';

const initialState = {
  all: [],
  activo: null,
  loadingAll: false,
  params: {},
};

export const statesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_STATES:
      return {
        ...state,
        all: action.payload.data,
        links: action.payload.links,
        meta: action.payload.meta,
      };
    case CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };
    case ACTIVO_STATES:
      return {
        ...state,
        activo: action.payload,
      };
    case LOADING_GETALL_STATES:
      return {
        ...state,
        loadingAll: action.payload,
      };
    case PARAMS_STATES:
      return {
        ...state,
        params: action.payload,
      };
    case SET_LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
