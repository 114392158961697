import { TableCell } from '@mui/material';
import { grey } from '@mui/material/colors';
import dayjs from 'dayjs';
import moment from 'moment-timezone';

export const getDateFormated = (date, format, timezone = 'America/Caracas') => {
  if (!date) {
    return null;
  }
  const result = moment(date);

  if (timezone) {
    result.tz(timezone);
  }
  return result.format(format);
};

export const getDateTimezone = (date, timezone = 'America/Caracas') => {
  if (!date) {
    return null;
  }
  return moment(date).tz(timezone, true).toDate();
};

export function isSwitch(isFuturo, date, dateTo) {
  const today = dayjs();
  const yesterday = today.subtract(1, 'day');
  const tomorrow = today.add(1, 'day');

  const isYesterdayTodayOrTomorrow =
    dayjs(isFuturo ? dateTo : date).isSame(yesterday, 'day') ||
    dayjs(isFuturo ? dateTo : date).isSame(today, 'day') ||
    dayjs(isFuturo ? dateTo : date).isSame(tomorrow, 'day');

  return isYesterdayTodayOrTomorrow;
}

export const dateformat = (
  date,
  {
    format = 'YYYY-MM-DD',
    full = false,
    isHHmm = false,
    isHHmmss = false,
    complete = false,
    dateOnly = false,
    timezone = 'America/Caracas',
  }
) => {
  if (timezone) {
    var _timezone = require('dayjs/plugin/timezone');
    dayjs.extend(_timezone);
    dayjs.tz.setDefault(timezone);
  }

  if (dateOnly) {
    return dayjs(complete ? `2000-01-01 ${date}` : date) ?? dayjs().toDate();
  }

  return dayjs((complete ? `2000-01-01 ${date}` : date) ?? dayjs()).format(
    full
      ? 'YYYY-MM-DD HH:mm:ss'
      : isHHmmss
      ? 'HH:mm:ss'
      : isHHmm
      ? 'HH:mm'
      : format
  );
};

export const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const getNameFromUserCode = (userCode, opts) => {
  if (!userCode) {
    return '';
  }
  const user = userCode.user;
  const profile = user.profile;
  let name = `${profile.firstName} ${profile.lastName}`;
  if (opts?.email) {
    name += ` - ${user.email}`;
  }
  if (opts?.code) {
    name += ` (${userCode.code.code})`;
  }
  return name;
};

export const stringAvatar = (name) => {
  return {
    sx: {
      bgcolor: grey[800],
      fontSize: '11pt',
      width: 30,
      height: 30,
    },
    children: `${name.split(' ')[0][0].toUpperCase()}${name
      .split(' ')[1][0]
      .toUpperCase()} `,
  };
};

export const validaBirthDate = (date, age, isMinorRegistrationAllowed = false) => {
  // Obten la fecha de nacimiento del usuario (puedes cambiar esto según tus necesidades)
  const fechaNacimiento = new Date(date); // Formato: YYYY-MM-DD

  // Obten la fecha actual
  const fechaActual = new Date();

  // Resta 10 años a la fecha actual para obtener la fecha límite
  const fechaLimite = new Date();
  fechaLimite.setFullYear(fechaActual.getFullYear() - (age ?? 9));

  // Compara las fechas y retorna el resultado
  const res = fechaNacimiento > fechaActual || (!isMinorRegistrationAllowed && fechaNacimiento > fechaLimite);
  return !res;
};

export const tryCatchHelper = ({ e, addNotification }) => {
  let message =
    e?.response?.data?.message ||
    e?.message ||
    'Error al intentar, realizar el proceso';

  if (Array.isArray(message)) {
    message = message.join(', ');
  }

  if (addNotification) {
    addNotification(message, { warning: true });
  } else {
    console.warn(message);
  }
};

export const centerLabelDataHelper = {
  setCellProps: () => ({
    align: 'center',
  }),
  customHeadRender: ({ index, ...column }) => {
    return (
      <TableCell key={index} align="center">
        {column.label}
      </TableCell>
    );
  },
};

export const mesesArray = [
  { nombre: 'Enero', valor: '01' },
  { nombre: 'Febrero', valor: '02' },
  { nombre: 'Marzo', valor: '03' },
  { nombre: 'Abril', valor: '04' },
  { nombre: 'Mayo', valor: '05' },
  { nombre: 'Junio', valor: '06' },
  { nombre: 'Julio', valor: '07' },
  { nombre: 'Agosto', valor: '08' },
  { nombre: 'Septiembre', valor: '09' },
  { nombre: 'Octubre', valor: '10' },
  { nombre: 'Noviembre', valor: '11' },
  { nombre: 'Diciembre', valor: '12' },
];


export const copyToClipboard = (value) => {
  navigator.clipboard.writeText(value).then(() => {
    // Puedes agregar una notificación aquí si lo deseas
    console.log('Texto copiado al portapapeles');
  }).catch(err => {
    console.error('Error al copiar el texto: ', err);
  });
};

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatTime = (time, use12Hours = true) => {
  return use12Hours ? dayjs(time).format('hh:mm A') : dayjs(time).format('HH:mm');
};

export const parseTime = (timeStr) => {
  const [h, m, s] = timeStr.split(':').map(Number);
  return dayjs().set('hour', h).set('minute', m).set('second', s || 0);
};
