import axios from 'axios';
import { baseApi, token } from '../../services/Apis';

export const morosidadApi = `${baseApi}/banks`;

export const getAllMorosidadService = async (params) => {
  const res = await axios.get(morosidadApi, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveMorosidadService = async (id, data) => {
  if (data.logo) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    data = formData;
  } else {
    delete data.logo;
  }

  let res;
  if (id) {
    res = await axios.put(`${morosidadApi}/${id}`, data, {
      headers: await token({
        isFormData: !!data.logo,
      }),
    });
  } else {
    res = await axios.post(`${morosidadApi}`, data, {
      headers: await token({
        isFormData: !!data.logo,
      }),
    });
  }

  return res;
};
